<template>
  <div>
    <a-table :columns="columns" :data-source="data"
             class="table"
             @change="tablePaginationChange"
             :pagination="pagination"
             :rowKey="(record, index) => { return index }"
             :scroll="{ y: 600 }"
             :loading="loading">
     <span slot="Operate" slot-scope="text,record">
       <a-button icon="right-circle" size="small" class="button" type="primary" @click="opanObject(record.id)"
                 ghost>详情</a-button>
     </span>

      <span slot="InShow" slot-scope="text">
        <a-badge v-if="text=='已处理'" status="success" text="已处理"/>
        <a-badge v-if="text=='未处理'" status="warning" text="未处理"/>
        <a-badge v-if="text=='无法处理'" status="error" text="无法处理"/>
      </span>
    </a-table>
  </div>
</template>

<script>

import {findCustom} from "@/api/Business/Details";

export default {
  data() {
    return {
      loading: false,
      data: [],
      columns: [
        {
          title: '维保编号',
          dataIndex: 'Order_id',
          key: 'Order_id',
          width: 150,
        },
        {
          title: '客户账号',
          dataIndex: 'User_Phone',
          key: 'User_Phone',
        },
        {
          title: '状态',
          dataIndex: 'InShow',
          key: 'InShow',
          scopedSlots: {customRender: 'InShow'},
        },
        {
          title: '提交时间',
          key: 'NewTime',
          dataIndex: 'NewTime',
          width: 220,
        },
        {
          title: '操作',
          key: 'Operate',
          dataIndex: 'Operate',
          scopedSlots: {customRender: 'Operate'},
          width: 250,
        },
      ],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    opanObject(id){
      this.$router.push({path: '/Custom/Code/Details', query: {id}})
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.data = []
      this.loading = true
      let pageIndex = this.pagination.current
      let pageSize = this.pagination.pageSize
      let id = this.$route.query.id
      findCustom({id, pageIndex, pageSize}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data
          for (let i in data) {
            let time = data[i].NewTime
            data[i].NewTime = this.getFormattedCurrentTime(time)
          }
          this.data = data
          this.pagination.total = res.payload.total
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    getFormattedCurrentTime(date) {
      const now = new Date(date);
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  }
}
</script>

<style scoped lang="scss">
.table{
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>