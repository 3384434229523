<template>
  <div class="Details">
    <a-button type="link" style="margin-bottom: 10px" @click="black">返回上一级</a-button>
    <div class="Info" style="height: 300px">
      <div class="id">推广员详情</div>
      <div class="data">
        <a-row class="row">
          <a-col :span="6" class="text">
            <b>部门</b>：{{ info.Department_Name }}
          </a-col>
          <a-col :span="6" class="text">
            <b>职位</b>：{{ info.Position_Name }}
          </a-col>
          <a-col :span="6" class="text">
            <b>类型</b>：{{ info.Class }}
          </a-col>
        </a-row>
        <a-row class="row">
          <a-col :span="6" class="text">
            <b>业务员姓名</b>：{{ info.Name }}
          </a-col>
          <a-col :span="6" class="text">
            <b>注册手机</b>：{{ info.Phone }}
          </a-col>
          <a-col :span="6" class="text">
            <b>注册时间</b>：{{ info.NewTime }}
          </a-col>
        </a-row>
        <a-row class="row" style="height: 100px;line-height: 100px">
          <a-col :span="6" class="text">
            <b>推广二维码</b><img
              :src="info.Code" alt=""
              class="code">
          </a-col>
          <a-col :span="6" class="text">
            <b>状态</b>
            <a-badge v-if="info.InShow=='正常'" style="margin-left: 10px" status="success" text="正常"/>
            <a-badge v-if="info.InShow=='禁用'" style="margin-left: 10px" status="error" text="禁用"/>
          </a-col>
          <a-col :span="6" class="text">
            <a-button icon="setting" size="small" class="button" type="primary" @click="setUpInShow(info.id)"
                      ghost>设置状态
            </a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="Info" style="height: 700px;padding-top: 40px">
      <a-radio-group v-model="value" @change="onChange" style="display:block;width: 90%;margin: 0 auto">
        <a-radio-button value="询价客户">
          询价客户
        </a-radio-button>
        <a-radio-button value="维保客户">
          维保客户
        </a-radio-button>
      </a-radio-group>
      <Inquiry ref="Inquiry" v-if="value=='询价客户'"></Inquiry>
      <Maintenance ref="Maintenance" v-if="value=='维保客户'"></Maintenance>


    </div>
    <EditInShow ref="EditInShow"></EditInShow>
  </div>
</template>

<script>
import indexTest from "./moble/indexTest";
import {fetchData} from "@/api/Business/Details";
import EditInShow from './com/EditInShow.vue'
import Inquiry from './com/Inquiry'
import Maintenance from './com/Maintenance'

let {columns} = indexTest
import QRCode from 'qrcode'

export default {
  data() {
    return {
      columns,
      value: '询价客户',
      data: [],
      loading: false,
      business_id: '',
      info: {}
    }
  },
  components: {EditInShow, Inquiry, Maintenance},
  mounted() {
    this.business_id = this.$route.query.id
    this.getdata()
    this.getcode()
  },
  methods: {
    async getcode(id) {
      return new Promise((resolve) => {
        let text = 'https://hospital.hs.3qlab.com?Business=' + id
        QRCode.toDataURL(text, {
          height: 370,
          width: 370
        }).then(base64 => {
          resolve(base64)
        })
      })
    },
    setUpInShow() {
      const commodity = this.info
      this.$refs.EditInShow.open(commodity);
    },
    getdata() {
      let id = this.business_id
      fetchData({id}).then(async res => {
        if (res.errorCode == 200) {
          let data = res.payload
          let time = data.NewTime
          let day = new Date(time);
          data.NewTime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          data.Code = await this.getcode(data.id)
          this.info = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    onChange(e) {
      this.value = e.target.value
    },
    black() {
      window.history.go(-1);
    }
  }
}
</script>

<style scoped lang="scss">
.Details {
  width: 100%;

}

.Info {
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 10px;

  .id {
    position: absolute;
    left: 50px;
    top: 40px;
    color: #666666;
    font-size: 20px;
    font-weight: bold;
  }

  .data {
    position: absolute;
    width: 100%;
    height: 65px;
    top: 70px;
    font-size: 14px;
    margin-top: 20px;

    .row {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 20px;

      .open {
        color: #58b99d;
        border-color: #58b99d;
        margin-left: 10px;
      }
    }

    .code {
      width: 100px;
      height: 100px;
      margin-left: 10px;
    }

    .text {
      //text-align: center;
    }
  }
}
</style>