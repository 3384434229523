
export default {
    columns:[

        {
            title: '权限',
            dataIndex: 'Authority',
            key: 'Authority',
            width: 120,
        },
        {
            title: '公司',
            dataIndex: 'Company_Name',
            key: 'Company_Name',
            width: 300,
        },
        {
            title: '部门',
            dataIndex: 'Department_Name',
            key: 'Department_Name',
        },
        {
            title: '职务',
            dataIndex: 'Position_Name',
            key: 'Position_Name',
        },
        {
            title: '姓名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '性别',
            dataIndex: 'Gender',
            key: 'Gender',
        },
        {
            title: '账号状态',
            key: 'InShow',
            dataIndex: 'InShow',
            scopedSlots: { customRender: 'InShow' },
        },

        {
            title: '手机',
            key: 'Phone',
            dataIndex: 'Phone',
            width: 120,
        },
        {
            title: '创建时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
            width: 380,
        },
    ]
}
