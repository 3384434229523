export default {
    columns:[
        {
            title: '公司名称',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '负责人姓名',
            dataIndex: 'UserName',
            key: 'UserName',
        },
        {
            title: '负责人联系方式',
            dataIndex: 'UserPhone',
            key: 'UserPhone',
        },

        {
            title: '新增时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
            width: 200,
        },
    ]
}
