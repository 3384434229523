<template>
<div class="Visualization">
  <Main></Main>
  <MapInfo></MapInfo>
  <Custom></Custom>
  <Salesman></Salesman>
</div>
</template>

<script>
import Main from './com/Main.vue'
import MapInfo from './com/MapInfo/index.vue'
import Custom from './com/Custom.vue'
import Salesman from './com/Salesman.vue'
export default {
  components:{
    Main,MapInfo,Custom,Salesman
  }
}

</script>

<style scoped lang="scss">
.Visualization{
  width: 100%;
  background-color: #f0f2f5;
  padding: 20px;
}
</style>