<template>
  <div class="Object">
    <div class="topBlock"></div>
    <a-table :columns="columns" :data-source="data"
             class="table"
             @change="tablePaginationChange"
             :pagination="pagination"
             :rowKey="(record, index) => { return index }"
             :scroll="{ y: 600 }"
             :loading="loading">
     <span slot="Operate" slot-scope="text,record">
       <a-button icon="right-circle" size="small" class="button" type="primary" @click="opanObject(record.id)"
                 ghost>详情</a-button>
     </span>
      <span slot="Business_id" slot-scope="text,record">
        <i v-if="record.Business_id">{{ record.Business_Name }}</i><i v-else style="color: brown">未分配</i>
     </span>

      <span slot="InShow" slot-scope="text">
        <a-badge v-if="text=='已处理'" status="success" text="已处理"/>
        <a-badge v-if="text=='未处理'" status="warning" text="未处理"/>
        <a-badge v-if="text=='无法处理'" status="error" text="无法处理"/>
      </span>
    </a-table>
  </div>
</template>

<script>
import indexTest from "./moble/indexTest";
import {fetchData} from "@/api/Custom/Maintenance";


let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    opanObject(id) {
      this.$router.push({path: '/Custom/Maintenance/Details', query: {id}})
    },
    getFormattedCurrentTime(date) {
      const now = new Date(date);
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getdata() {
      this.data = []
      this.loading = true
      let pageIndex = this.pagination.current
      let pageSize = this.pagination.pageSize
      fetchData({pageIndex, pageSize}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data
          for (let i in data) {
            let time = data[i].NewTime
            data[i].NewTime = this.getFormattedCurrentTime(time)
          }
          this.data = data
          this.pagination.total = res.payload.total
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }


  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }
}

.table {
  width: 98%;
  margin: 0 auto;
}

.green {
  color: green;
}

.red {
  background-color: red;
}

.button {
  display: inline-block;
  margin-right: 15px;
}


</style>
