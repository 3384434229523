<template>
  <a-spin :spinning="loading">
  <div class="left">
    <div class="ranking">
      <div class="nav">平台获客渠道分析</div>
      <div class="main">
        <a-table :columns="columns" :data-source="data"
                 :pagination="false"
                 :rowKey="(record, index) => { return index }"
                 :loading="loading">
            <span slot="id" slot-scope="tags,record,index">
              {{ index + 1 }}
            </span>
        </a-table>
      </div>
    </div>
  </div>
  </a-spin>
</template>

<script>
import indexTest from '../moble/indexTest'
import {findCustom_user} from "@/api/Statistics/Visualization";

let {columns_3} = indexTest
export default {
  data() {
    return {
      loading: false,
      columns: columns_3,
      data: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading=true
      findCustom_user().then(res => {
        this.loading=false
        if (res.errorCode == 200) {
          let data_1 = res.payload
          this.data = data_1
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@mixin ranking {
  position: relative;
  background-color: #fff;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  .nav {
    color: #666666;
    width: 100%;
    height: 55px;
    line-height: 55px;
    font-size: 13px;
    font-weight: bold;
    background-color: #f9f9f9;
    text-indent: 1rem;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    border: 1px solid #e9e9e9;
  }
  .main {
    padding: 20px;
    height: 765px;
  }
}

.left {
  width: 100%;

  .ranking {
    width: 98%;
    height: 820px;
    margin-bottom: 20px;
    @include ranking;
  }
}


</style>