import request from '../../utils/request';

//查询
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Custom/User/find',
        method: 'get',
        params: query
    });
};

//查询详情
export const FindDetails = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Custom/User/find/Details',
        method: 'get',
        params: query
    });
};