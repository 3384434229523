<template>
  <a-spin :spinning="load">
    <div class="main">
      <a-row>
        <a-col :span="6">
          <div class="block">
            <div class="icon data-user"></div>
            <div class="title">入驻平台业务员</div>
            <div class="data">{{data.business}}</div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="block">
            <div class="icon data-swipe"></div>
            <div class="title">客户总数</div>
            <div class="data">{{data.custom_user}}</div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="block">
            <div class="icon data-money"></div>
            <div class="title">精准询价需求总数</div>
            <div class="data">{{data.custom_inquiry}}</div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="block">
            <div class="icon data-ctivitynumber"></div>
            <div class="title">维保需求总数</div>
            <div class="data">{{data.custom_maintenance}}</div>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-spin>
</template>


<script>
// import {initActivity} from "@/api/Visualization";

import {findMain} from "@/api/Statistics/Visualization";

export default {
  data() {
    return {
      load: false,
      data: {}
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.load=true
      findMain().then(res => {
        this.load=false
        if (res.errorCode == 200) {
          let data = res.payload
          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    }
  }
}


</script>

<style scoped lang="scss">
.main {
  width: 96%;
  height: 120px;
  margin: 0 auto;
  margin-bottom: 20px;

  .block {
    width: 95%;
    height: 120px;
    background-color: #f9f9f9;
    border-radius: 15px;
    border: 1px solid #e9e9e9;
    margin: 0 auto;

    .title {
      width: 100%;
      font-size: 15px;
      position: absolute;
      left: 120px;
      top: 60px;
    }

    .data {
      width: 100%;
      font-size: 25px;
      position: absolute;
      left: 120px;
      top: 25px;
      font-weight: bold;
    }

    .icon {
      width: 60px;
      height: 60px;
      position: absolute;
      border-radius: 50px;
      left: 30px;
      top: 30px;
    }

    @mixin data-icon {
      background-size: 60% 60%;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .data-user {
      @include data-icon;
      background-image: url("../../../../assets/dashborard/data-user.png");
      background-color: #5b8ff9;
    }

    .data-money {
      @include data-icon;
      background-image: url("../../../../assets/dashborard/data-money.png");
      background-color: #5ad8a6;
    }

    .data-swipe {
      @include data-icon;
      background-image: url("../../../../assets/dashborard/data-swipe.png");
      background-color: #ffa94c;
    }

    .data-ctivitynumber {
      @include data-icon;
      background-image: url("../../../../assets/dashborard/data-maintenance.png");
      background-color: #6dc8ec;
    }


  }
}
</style>