var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Login"},[_c('div',{staticClass:"bg"}),_c('div',{staticClass:"main"},[_vm._m(0),_c('div',{staticClass:"title"},[_vm._v("实验室小助手信息管理平台")]),_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Phone',
                          {  rules: [{ required: true, message: '请输入账号' }] }
           ]),expression:"[\n                          'Phone',\n                          {  rules: [{ required: true, message: '请输入账号' }] }\n           ]"}],attrs:{"size":"large","placeholder":"请输入账号"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Password',
                           {  rules: [{ required: true, message: '请输入密码' }] }
                      ]),expression:"[\n                          'Password',\n                           {  rules: [{ required: true, message: '请输入密码' }] }\n                      ]"}],attrs:{"size":"large","placeholder":"请输入密码"}},[_vm._v(" > "),_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Code',
                          {  rules: [{ required: true, message: '请输入校验码' }] }
           ]),expression:"[\n                          'Code',\n                          {  rules: [{ required: true, message: '请输入校验码' }] }\n           ]"}],staticClass:"CodeInput",attrs:{"size":"large","placeholder":"请输入校验码"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"safety"},slot:"prefix"})],1),_c('div',{staticClass:"Code",on:{"click":_vm.init}},[_c('canvas',{attrs:{"id":"captchaCanvas"}})])],1)],1),_c('div',{staticClass:"nav"},[_c('a-checkbox',{on:{"change":_vm.onChange}},[_vm._v(" 记住密码 ")]),_c('div',{staticClass:"right"},[_c('a-button',{attrs:{"type":"link"},on:{"click":_vm.openForget}},[_vm._v(" 忘记密码 ")])],1)],1),_c('a-button',{staticClass:"go-login",attrs:{"block":"","size":"large"},on:{"click":_vm.Login}},[_vm._v(" 登录 ")])],1),_c('forget',{ref:"forget"}),_c('add',{ref:"add"}),_c('selectCompany',{ref:"selectCompany"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":"/images/logo.png","alt":"logo"}})])
}]

export { render, staticRenderFns }