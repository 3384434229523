<template>
  <div class="Object">
    <a-button style="margin-bottom: 10px;margin-top: 15px;margin-left: 15px" @click="openAdd">添加外部业务员</a-button>
    <div class="topBlock"></div>
    <a-table :columns="columns" :data-source="data"
             :pagination="pagination"
             @change="tablePaginationChange"
             :rowKey="(record, index) => { return index }"
             class="table"
             :scroll="{ y: 600 }"
             :loading="loading">
     <span slot="Operate" slot-scope="text,record">
       <a-button icon="eye" size="small" class="button" type="primary" @click="opanObject(record.id)"
                 ghost>查看</a-button>
      <a-button icon="setting" size="small" class="button" type="primary" @click="setUpInShow(record.id)"
                ghost>设置状态</a-button>
       <a-button icon="delete" size="small" class="button" type="danger" @click="deletes(record.id)"
                 ghost>删除</a-button>
     </span>
      <span slot="InShow" slot-scope="text">
        <a-badge v-if="text=='正常'" status="success" text="正常"/>
        <a-badge v-if="text=='禁用'" status="error" text="禁用"/>
      </span>
      <span slot="Promotion" slot-scope="text">
        <img :src="text" alt="" style="width: 80px">
      </span>
    </a-table>
    <Add ref="Add" :isEdit="false"></Add>
    <EditInShow ref="EditInShow"></EditInShow>
  </div>
</template>

<script>
import indexTest from "./moble/indexTest";
import Add from "./com/Add.vue"
import {deleteData, fetchData} from "@/api/Business/External";
import EditInShow from './com/EditInShow.vue'
import QRCode from "qrcode";

let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
    }
  },
  components: {Add, EditInShow},
  mounted() {
    this.getdata()
  },
  methods: {
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    async getcode(id) {
      return new Promise((resolve) => {
        let text = 'https://hospital.hs.3qlab.com?BusinessID=' + id
        QRCode.toDataURL(text, {
          height: 370,
          width: 370
        }).then(base64 => {
          resolve(base64)
        })
      })
    },
    deletes(id) {
      let that = this
      this.$confirm({
        title: '是否删除该业务员吗?',
        content: () => <div style="color:red;">注意：删除业务员导致无法登录，请小心操作！</div>,
        onOk() {
          deleteData(id).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        },
      });
    },
    setUpInShow(id) {
      const commodity = this.data.find(item => item.id == id);
      this.$refs.EditInShow.open(commodity);
    },
    getdata() {
      this.data = []
      this.loading = true
      let pageIndex = this.pagination.current
      let pageSize = this.pagination.pageSize
      fetchData({pageIndex, pageSize}).then(async res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data
          for (let i in data) {
            let time = data[i].NewTime
            let day = new Date(time);
            data[i].NewTime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
            data[i].Promotion = await this.getcode(data.id)
          }
          this.data = data
          this.pagination.total = res.payload.total
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    openAdd() {
      this.$refs.Add.open()
    },
    opanObject(id) {
      this.$router.push({path: '/Business/Details', query: {id}})
    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }


  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }
}

.table {
  width: 98%;
  margin: 0 auto;
}

.green {
  color: green;
}

.red {
  background-color: red;
}

.button {
  display: inline-block;
  margin-right: 15px;
}


</style>
