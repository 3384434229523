var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":_vm.isEdit ? '编辑内部业务员' : '添加内部业务员',"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"width":"500px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"登录账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Phone',
                              {  rules: [{ required: true, message: '请输入登录账号' }] ,initialValue: _vm.isEdit ? _vm.commodityDetail.Phone : ''},
                          ]),expression:"[\n                              'Phone',\n                              {  rules: [{ required: true, message: '请输入登录账号' }] ,initialValue: isEdit ? commodityDetail.Phone : ''},\n                          ]"}],attrs:{"placeholder":"请输入登录账号（手机号码）","disabled":_vm.isEdit}})],1),_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Name',
                              {  rules: [{ required: true, message: '请输入姓名' }] ,initialValue: _vm.isEdit ? _vm.commodityDetail.Name : ''},
                          ]),expression:"[\n                              'Name',\n                              {  rules: [{ required: true, message: '请输入姓名' }] ,initialValue: isEdit ? commodityDetail.Name : ''},\n                          ]"}],attrs:{"disabled":_vm.isEdit,"placeholder":"请输入姓名"}})],1),_c('a-form-item',{attrs:{"label":"公司"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Company_id',
                              {  rules: [{ required: true, message: '请选择部门' }] ,initialValue: _vm.isEdit ? _vm.commodityDetail.Company_Name : ''},

        ]),expression:"[\n                              'Company_id',\n                              {  rules: [{ required: true, message: '请选择部门' }] ,initialValue: isEdit ? commodityDetail.Company_Name : ''},\n\n        ]"}],attrs:{"placeholder":"请选择公司","disabled":_vm.isEdit}},_vm._l((_vm.CompanyData),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.Name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"所属部门"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Department_id',
                              {  rules: [{ required: true, message: '请选择部门' }] ,initialValue: _vm.isEdit ? _vm.commodityDetail.Department_Name : ''},

        ]),expression:"[\n                              'Department_id',\n                              {  rules: [{ required: true, message: '请选择部门' }] ,initialValue: isEdit ? commodityDetail.Department_Name : ''},\n\n        ]"}],attrs:{"placeholder":"请选择部门","disabled":_vm.isEdit},on:{"change":_vm.handleChange}},_vm._l((_vm.DepartmentData),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.Name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"职务"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Position_id',
                              {  rules: [{ required: true, message: '请选择职务' }] ,initialValue: _vm.isEdit ? _vm.commodityDetail.Position_Name : ''},
        ]),expression:"[\n                              'Position_id',\n                              {  rules: [{ required: true, message: '请选择职务' }] ,initialValue: isEdit ? commodityDetail.Position_Name : ''},\n        ]"}],attrs:{"placeholder":"请选择职务","disabled":_vm.isEdit||!_vm.Department}},_vm._l((_vm.PositionData),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.Name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'Gender',
                           {  rules: [{ required: true, message: '请选择性别' }] ,initialValue: _vm.isEdit ? _vm.commodityDetail.Gender : '' }
                      ]),expression:"[\n                          'Gender',\n                           {  rules: [{ required: true, message: '请选择性别' }] ,initialValue: isEdit ? commodityDetail.Gender : '' }\n                      ]"}],attrs:{"options":_vm.plainOptions,"disabled":_vm.isEdit}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }