import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes.js'


Vue.use(Router) // Vue 全局使用Router

const router = new Router({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    const info = localStorage.getItem("info");

    if (!info && to.path !== '/Login') {
        next('/Login');
    }  else {
        next();
    }
})

export default router;

