import LoginView from '@/pages/Login/index';
import Layout from '@/pages/Layout';
import OperateView from '@/pages/Operate/index.vue'
import SetUpView from '@/pages/SetUp/index'
import BusinessView from '@/pages/Business/index.vue'
import StatisticsView from '@/pages/Statistics/index.vue'
import CustomView from '@/pages/Custom/index.vue'
import AdminView from '@/pages/Admin/index.vue'


const AdminTab = [
    {
        path: '/',
        name: '/',
        redirect: '/Statistics/Visualization',
        component: Layout,
        children: [
            {
                path: '/Admin',
                redirect: '/Admin/Department',
                component: AdminView,
                children: [
                    {
                        path: 'Company',
                        component: () => import('@/pages/Admin/Company/index.vue'),
                        meta: {
                            title: '公司列表'
                        },
                    },
                    {
                        path: 'Department',
                        component: () => import('@/pages/Admin/Department/index.vue'),
                        meta: {
                            title: '部门列表'
                        },
                    },
                    {
                        path: 'Position',
                        component: () => import('@/pages/Admin/Position/index.vue'),
                        meta: {
                            title: '职位列表'
                        },
                    },
                    {
                        path: 'User',
                        component: () => import('@/pages/Admin/User/index.vue'),
                        meta: {
                            title: '管理员列表'
                        },
                    },
                ]
            },
            {
                path: '/Statistics',
                redirect: '/Statistics/Visualization',
                component: StatisticsView,
                children: [
                    {
                        path: 'Visualization',
                        component: () => import('@/pages/Statistics/Visualization/index.vue'),
                        meta: {
                            title: '数据统计'
                        },
                    },
                ]
            },
            {
                path: '/Custom',
                redirect: '/Custom/Code',
                component: CustomView,
                children: [
                    {
                        path: 'Code',
                        component: () => import('@/pages/Custom/Code/index.vue'),
                        meta: {
                            title: '精准询价客户'
                        },
                    },
                    {
                        path: 'Code/Details',
                        component: () => import('@/pages/Custom/Code/com/Details.vue'),
                        meta: {
                            title: '详情'
                        },
                    },
                    {
                        path: 'User',
                        component: () => import('@/pages/Custom/User/index.vue'),
                        meta: {
                            title: '投流渠道客户'
                        },
                    },
                    {
                        path: 'User/Details',
                        component: () => import('@/pages/Custom/User/com/Details.vue'),
                        meta: {
                            title: '详情'
                        },
                    },
                    {
                        path: 'Maintenance',
                        component: () => import('@/pages/Custom/Maintenance/index.vue'),
                        meta: {
                            title: '维保客户'
                        },
                    },
                    {
                        path: 'Maintenance/Details',
                        component: () => import('@/pages/Custom/Maintenance/com/Details.vue'),
                        meta: {
                            title: '详情'
                        },
                    },
                ]
            },

            {
                path: '/Business',
                redirect: '/Business/Internal',
                component: BusinessView,
                children: [
                    {
                        path: 'Details',
                        component: () => import('@/pages/Business/Details/index.vue'),
                        meta: {
                            title: '详情'
                        },
                    },
                    {
                        path: 'Internal',
                        component: () => import('@/pages/Business/Internal/index.vue'),
                        meta: {
                            title: '内部业务员'
                        },
                    },
                    {
                        path: 'External',
                        component: () => import('@/pages/Business/External/index.vue'),
                        meta: {
                            title: '外部业务员'
                        },
                    },
                    {
                        path: 'PrintCode',
                        component: () => import('@/pages/Business/PrintCode/index.vue'),
                        meta: {
                            title: '设备标签打印'
                        },
                    },
                ]
            },
            {
                path: '/Operate',
                redirect: '/Operate/Notice',
                component: OperateView,
                children: [
                    {
                        path: 'Investment',
                        component: () => import('@/pages/Operate/Investment/index.vue'),
                        meta: {
                            title: '投流渠道管理'
                        },
                    },
                    {
                        path: 'Notice',
                        component: () => import('@/pages/Operate/Notice/index.vue'),
                        meta: {
                            title: '公告管理'
                        },
                    },
                    {
                        path: 'Feedback',
                        component: () => import('@/pages/Operate/Feedback/index.vue'),
                        meta: {
                            title: '反馈工单'
                        },
                    },
                    {
                        path: 'Agreement',
                        component: () => import('@/pages/Operate/Agreement/index.vue'),
                        meta: {
                            title: '协议目录'
                        },
                    }
                ]
            },
            {
                path: '/SetUp',
                redirect: '/SetUp/Info',
                component: SetUpView,
                children: [
                    {
                        path: 'Info',
                        component: () => import('@/pages/SetUp/page/Info/index.vue'),
                        meta: {
                            title: '账户信息'
                        },
                    },
                    {
                        path: 'Secure',
                        component: () => import('@/pages/SetUp/page/Secure/index.vue'),
                        meta: {
                            title: '账户安全'
                        },
                    },
                ]
            }
        ]
    }
]


const LoginTab = [
    {
        path: '/Login',
        component: LoginView,
        children: [],
        meta: {
            title: '实验室小助手信息管理平台'
        }
    },
]


const routes = [...AdminTab, ...LoginTab];

export default routes;