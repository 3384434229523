<template>
  <a-spin :spinning="loading">
    <div class="Salesman">
      <div class="nav">业务员增长趋势分析</div>
      <div class="main">
        <div id="container_5"></div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import {Line} from '@antv/g2plot';
import {findPlatformBusiness} from "@/api/Statistics/Visualization";

export default {
  data() {
    return {
      loading: false,
      data: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      findPlatformBusiness().then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload
          this.data = data.sum
          this.canvasInit()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    canvasInit() {
      let data = this.data
      const piePlot = new Line('container_5', {
        data,
        padding: 'auto',
        xField: '月份',
        yField: '新增人数',
        xAxis: {
          // type: 'timeCat',
          tickCount: 7,
        },
        color: '#ff7a8c',
      })
      piePlot.render();
    }
  }
}
</script>

<style scoped lang="scss">
@mixin ranking {
  position: relative;
  background-color: #fff;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  .nav {
    color: #666666;
    width: 100%;
    height: 55px;
    line-height: 55px;
    font-size: 13px;
    font-weight: bold;
    background-color: #f9f9f9;
    text-indent: 1rem;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    border: 1px solid #e9e9e9;
  }
  .main {
    padding: 20px;
    height: 345px;
  }
}

.Salesman {
  width: 95%;
  height: 400px;
  margin: 0 auto;
  margin-bottom: 20px;
  @include ranking;

  #container_5 {
    width: 95%;
    height: 300px;
  }
}
</style>