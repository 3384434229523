<template>
  <div class="Object">
    <div class="topBlock"></div>
    <a-table :columns="columns" :data-source="data"
             :rowKey="(record, index) => { return index }"
             class="table"
             :scroll="{ y: 600 }"
             :loading="loading">
     <span slot="Operate" slot-scope="text,record">
       <a-button icon="right-circle" size="small" class="button" type="primary" @click="opanObject(record.id)" ghost>编辑</a-button>
       <a-button icon="right-circle" size="small" class="button" type="primary" @click="opanObject(record.id)" ghost>关闭</a-button>
       <a-button icon="right-circle" size="small" class="button" type="primary" @click="opanObject(record.id)" ghost>删除</a-button>
     </span>
    </a-table>
  </div>
</template>

<script>
import indexTest from "./moble/indexTest";


let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [{id: 20208447578, Time: '2020-11-25 23:26:08'}],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
    }
  },
  mounted() {

  },
  methods: {
    opanObject() {

    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }


  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }
}

.table {
  width: 98%;
  margin: 0 auto;
}

.green {
  color: green;
}

.red {
  background-color: red;
}

.button {
  display: inline-block;
  margin-right: 15px;
}


</style>
