
export default {
    columns:[
        {
            title: '部门',
            dataIndex: 'Department_Name',
            key: 'Department_Name',
        },
        {
            title: '职务名称',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '状态',
            dataIndex: 'InShow',
            key: 'InShow',
        },
        {
            title: '成员数量',
            dataIndex: 'UserNumber',
            key: 'UserNumber',
        },
        {
            title: '描述',
            dataIndex: 'DescribeText',
            key: 'DescribeText',
        },
        {
            title: '添加时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
            width: 450,
        },
    ]
}
