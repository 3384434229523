<template>
<div class="MapInfo">
  <a-row>
    <a-row type="flex">
      <a-col flex="auto">
        <left ></left>
      </a-col>
      <a-col flex="600px">
        <right></right>
      </a-col>
    </a-row>
  </a-row>
</div>
</template>

<script>
import right from './com/right.vue'
import left from './com/left.vue'
export default {
  components:{
    right,left
  }

}
</script>

<style scoped lang="scss">
.MapInfo{
  width: 95%;
  height: 830px;
  margin: 0 auto ;
}
</style>