import request from '../../utils/request';

//查询
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Business/Details/find',
        method: 'get',
        params: query
    });
};


//查询询价订单
export const findCustom = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Business/Details/find/Custom',
        method: 'get',
        params: query
    });
};


//查询维保订单
export const findMaintenance = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Business/Details/find/Maintenance',
        method: 'get',
        params: query
    });
};
