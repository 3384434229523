var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"添加公司","visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"width":"500px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"公司昵称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Name',
                              {  rules: [{ required: true, message: '请输入公司昵称' }] },
                          ]),expression:"[\n                              'Name',\n                              {  rules: [{ required: true, message: '请输入公司昵称' }] },\n                          ]"}],attrs:{"placeholder":"请输入公司昵称"}})],1),_c('a-form-item',{attrs:{"label":"公司负责人姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'UserName',
                              {  rules: [{ required: true, message: '请输入公司负责人姓名' }] },
                          ]),expression:"[\n                              'UserName',\n                              {  rules: [{ required: true, message: '请输入公司负责人姓名' }] },\n                          ]"}],attrs:{"placeholder":"请输入公司负责人姓名"}})],1),_c('a-form-item',{attrs:{"label":"负责人联系方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'UserPhone',
                              {  rules: [{ required: true, message: '请输入负责人联系方式' }] },
                          ]),expression:"[\n                              'UserPhone',\n                              {  rules: [{ required: true, message: '请输入负责人联系方式' }] },\n                          ]"}],attrs:{"placeholder":"请输入负责人联系方式"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }