
export default {
    columns:[
        {
            title: '工单编号',
            dataIndex: 'IdNumber',
            key: 'IdNumber',
        },
        {
            title: '工单类型',
            dataIndex: 'Class',
            key: 'Class',
        },
        {
            title: '处理人员',
            dataIndex: 'ProcessingUser',
            key: 'ProcessingUser',
        },
        {
            title: '工单状态',
            key: 'InShow',
            dataIndex: 'InShow',
            scopedSlots: { customRender: 'InShow' },
        },
        {
            title: '联系方式',
            key: 'Phone',
            dataIndex: 'Phone',
        },
        {
            title: '提交时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
            width: 250,
        },
    ]
}
