<template>
  <div>
    <a-modal
        title="企业申请"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="注册手机">
          <a-input-search
              :loading="loadingCode"
              placeholder="请输入注册手机"
              :enter-button="StartText"
              @search="setCode"
              v-decorator="[
                            'Phone',
                            {  rules: [{required: true,message: '请输入注册手机' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="手机验证码">
          <a-input
              placeholder="请填写获取的手机验证码"
              v-decorator="[
                            'Code',
                            {  rules: [{required: true,message: '请填写获取的手机验证码' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="管理员姓名">
          <a-input
              placeholder="管理员姓名"
              v-decorator="[
                            'User',
                            {  rules: [{required: true,message: '请填写管理员姓名' }] }
             ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="企业logo">
          <a-upload
              :headers="{
                  'X-Requested-With':null
              }"
              name="file"
              list-type="picture-card"
              :show-upload-list="false"
              :action="ImageUrl"
              @change="AddImage"
          >
            <img v-if="LogoImage" :src="LogoImage" alt="avatar" class="avatar"/>
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">
                上传头像
              </div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="企业昵称">
          <a-input
              placeholder="企业昵称"
              v-decorator="[
                            'CompanyName',
                            {  rules: [{required: true,message: '请填写企业昵称' }] }
             ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="法定代表人">
          <a-input
              placeholder="请填写法定代表人"
              v-decorator="[
                            'UserName',
                            {  rules: [{required: true,message: '请填写法定代表人' }] }
             ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="法定代表人联系方式">
          <a-input
              placeholder="请填写法定代表人联系方式"
              v-decorator="[
                            'UserPhone',
                            {  rules: [{required: true,message: '请填写法定代表人联系方式' }] }
             ]"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="社会信用代码">
          <a-input
              placeholder="请填写社会信用代码"
              v-decorator="[
                            'ThirdPartyUserId',
                            {  rules: [{required: true,message: '请填写社会信用代码' }] }
             ]"
          >
          </a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
// import {sendingCode} from "@/api/SetUp/Secure";
// import {ApplyCompany} from '@/api/Login'

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      StartText: '发送验证码',
      Time: 0,
      loading:false,
      loadingCode: false,
      ImageUrl: process.env.VUE_APP_URL + 'Enterprise/Company/image',
      LogoImage:''
    };
  },
  methods: {
    //打开
    open() {
      this.visible = true;
      this.LogoImage = ''
      this.form.resetFields()
    },
    //确认申请新企业
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.confirmLoading = true;
          console.log(values)
          // let Phone = values.Phone
          // let User=values.User
          // let Code = values.Code
          // let LogoImage = this.LogoImage
          // let CompanyName=values.CompanyName
          // let UserName = values.UserName
          // let UserPhone = values.UserPhone
          // let ThirdPartyUserId = values.ThirdPartyUserId


          // ApplyCompany({Phone,User,Code,LogoImage,CompanyName,UserName,UserPhone,ThirdPartyUserId}).then(res=>{
          //   this.confirmLoading = false
          //   if (res.errorCode == 200) {
          //     this.$message.success(res.payload);
          //     this.handleCancel()
          //   } else {
          //     this.$message.error(res.errorMsg);
          //   }
          // })
        }
      })
    },
    //取消并关闭
    handleCancel() {
      this.visible = false;
      this.form.resetFields()
    },
    //发送验证码
    setCode() {
      let form = this.form.getFieldsValue()
      let Phone = form.Phone

      if (!Phone) {
        this.$message.error('手机号码为空');
        return
      }

      let time = this.Time

      if (time == 0) {

        this.loadingCode = true

        // sendingCode({Phone}).then(res => {
        //   this.loadingCode = false
        //   if (res.errorCode == 200) {
        //     this.$message.success(res.payload);
        //     this.SetTime()
        //   } else {
        //     this.$message.error(res.errorMsg);
        //   }
        // })
      }
    },
    //图片上传
    AddImage(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }

      let response = info.file.response
      if (response) {
        if (response.errorCode == 200) {
          this.loading = false
          let url = response.payload
          this.LogoImage=url
        }
      }
    },
  },
};
</script>
