<template>
  <div>
    <a-modal
        title="重置密码"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
    >
      <a-form :form="form">
        <a-form-item label="注册手机">
          <a-input-search
              :loading="loadingCode"
              placeholder="请输入注册手机"
              :enter-button="StartText"
              @search="setCode"
              v-decorator="[
                            'Phone',
                            {  rules: [{required: true,message: '请输入注册手机' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="新密码">
          <a-input-password
              placeholder="请输入新密码"
              v-decorator="[
                            'newPassword',
                            {  rules: [{required: true,message: '请输入新密码' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input-password
              placeholder="请再次确认密码"
              v-decorator="[
                            'confirmPassword',
                            {  rules: [{required: true,message: '请再次确认密码' }] }
             ]"
          />
        </a-form-item>
        <a-form-item label="手机验证码">
          <a-input
              placeholder="请填写获取的手机验证码"
              v-decorator="[
                            'Code',
                            {  rules: [{required: true,message: '请填写获取的手机验证码' }] }
             ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
//修改密码
// import {ModifyPow} from "@/api/Login";
//发送验证码
// import {sendingCode} from "@/api/SetUp/Secure";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      loadingCode: false,
      form: this.$form.createForm(this),
      StartText: '发送验证码',
      Time: 0
    };
  },
  methods: {
    //打开
    open() {
      this.visible = true;
      this.form.resetFields()
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.confirmLoading = true;
          console.log(values)
          // let Code = values.Code
          // let Phone = values.Phone
          // let newPassword = values.newPassword
          // let confirmPassword = values.confirmPassword

          // ModifyPow({Code, Phone, newPassword, confirmPassword}).then(res => {
          //   this.confirmLoading = false
          //   if (res.errorCode == 200) {
          //     this.$message.success(res.payload);
          //     this.handleCancel()
          //   } else {
          //     this.$message.error(res.errorMsg);
          //   }
          // })
        }
      })
    },
    //取消并清空数据
    handleCancel() {
      this.visible = false;
    },
    //发送验证码
    setCode() {
      let form = this.form.getFieldsValue()
      let Phone = form.Phone

      if (!Phone) {
        this.$message.error('手机号码为空');
        return
      }

      let time = this.Time

      if (time == 0) {

        this.loadingCode = true

        // sendingCode({Phone}).then(res => {
        //   this.loadingCode = false
        //   if (res.errorCode == 200) {
        //     this.$message.success(res.payload);
        //     this.SetTime()
        //   } else {
        //     this.$message.error(res.errorMsg);
        //   }
        // })
      }
    },
    //开始计时
    SetTime() {
      this.Time = 60
      this.CodeTime = setInterval(() => {
        let times = this.Time
        times = times - 1
        if (times == 0) {
          clearInterval(this.CodeTime)
          this.StartText = '获取验证码'
          this.Time = 0
          return
        }
        this.Time = times
        this.StartText = times + '秒再次发送'
      }, 1000)
    }
  },
};
</script>
