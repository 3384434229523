
export default {
    columns:[
        {
            title: '询价编号',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '所属业务员',
            dataIndex: 'BusinessName',
            key: 'BusinessName',
        },
        {
            title: '状态',
            dataIndex: 'InShow',
            key: 'InShow',
        },
        {
            title: '姓名',
            dataIndex: 'UserName',
            key: 'UserName',
        },
        {
            title: '公司名称',
            key: 'CompanyName',
            dataIndex: 'CompanyName',
        },
        {
            title: '手机号',
            key: 'UserPhone',
            dataIndex: 'UserPhone',
        },
        {
            title: '提交时间',
            key: 'Time',
            dataIndex: 'Time',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
            width: 100,
        },
    ]
}
