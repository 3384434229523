<template>
  <div>
    <a-modal
        :title="isEdit ? '编辑员工' : '添加员工'"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        width="500px"
    >
      <a-form :form="form">
        <a-form-item label="登录账号">
          <a-input
              placeholder="请输入登录账号（手机号码）"
              :disabled="isEdit"
              v-decorator="[
                                'Phone',
                                {  rules: [{ required: true, message: '请输入登录账号' }] ,initialValue: isEdit ? commodityDetail.Phone : ''},
                            ]"
          />
        </a-form-item>
        <a-form-item label="公司">
          <a-select placeholder="请选择公司"
                    :disabled="isEdit"
                    v-decorator="[
                                'Company_id',
                                {  rules: [{ required: true, message: '请选择部门' }] ,initialValue: isEdit ? commodityDetail.Company_Name : ''},

          ]">
            <a-select-option :value="item.id" v-for="(item,index) in CompanyData" :key="index">
              {{ item.Name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="所属部门">
          <a-select placeholder="请选择部门"
                    @change="handleChange"
                    :disabled="isEdit"
                    v-decorator="[
                                'Department_id',
                                {  rules: [{ required: true, message: '请选择部门' }] ,initialValue: isEdit ? commodityDetail.Department_Name : ''},

          ]">
            <a-select-option :value="item.id" v-for="(item,index) in DepartmentData" :key="index">
              {{ item.Name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="职务">
          <a-select placeholder="请选择职务"
                    :disabled="isEdit||!Department"
                    v-decorator="[
                                'Position_id',
                                {  rules: [{ required: true, message: '请选择职务' }] ,initialValue: isEdit ? commodityDetail.Position_Name : ''},
          ]">
            <a-select-option :value="item.id" v-for="(item,index) in PositionData" :key="index">
              {{ item.Name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="密码">
          <a-input-password
              placeholder="请输入密码"
              v-decorator="[
                            'Password',
                             {  rules: [{ required: true, message: '请输入密码' }] }
                        ]">
          </a-input-password>
        </a-form-item>
        <a-form-item label="姓名">
          <a-input
              :disabled="isEdit"
              placeholder="请输入姓名"
              v-decorator="[
                                'Name',
                                {  rules: [{ required: true, message: '请输入姓名' }] ,initialValue: isEdit ? commodityDetail.Name : ''},
                            ]"
          />
        </a-form-item>
        <a-form-item label="性别">
          <a-radio-group :options="plainOptions"
                         :disabled="isEdit"
                         v-decorator="[
                            'Gender',
                             {  rules: [{ required: true, message: '请选择性别' }] ,initialValue: isEdit ? commodityDetail.Gender : '' }
                        ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>

</template>
<script>
import {add, edit, fetchcompany, fetchDepartment, fetchPosition} from "@/api/Admin/User";

const plainOptions = ['男', '女'];
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      plainOptions,
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      Department: false,
      DepartmentData: [],
      PositionData: [],
      CompanyData: [],
      commodityDetail: {}
    };
  },
  methods: {
    init(){
      fetchDepartment().then(res => {
        if (res.errorCode == 200) {
          this.DepartmentData = res.payload
        } else {
          this.$message.error(res.errorMsg);
        }
      })

      fetchcompany().then(res => {
        if (res.errorCode == 200) {
          this.CompanyData = res.payload
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    handleChange(Department_id) {
      fetchPosition({Department_id}).then(res => {
        if (res.errorCode == 200) {
          this.PositionData = res.payload
        } else {
          this.$message.error(res.errorMsg);
        }
      })
      this.Department = true
    },
    //打开
    open(commodity) {
      this.visible = true;
      this.form.resetFields()
      this.commodityDetail = commodity
      this.init()
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let Company_id = values.Company_id
          let Position_id = values.Position_id
          let Department_id = values.Department_id
          let Name = values.Name
          let Phone = values.Phone
          let Gender = values.Gender
          let Password = values.Password
          this.confirmLoading = true;
          if (this.isEdit) {
            this.confirmLoading = false;
            let id = this.commodityDetail.id
            edit({id, Password}).then(res => {
              this.confirmLoading = false;
              if (res.errorCode == 200) {
                this.$message.success(res.payload);
                this.handleCancel()
                this.$parent.getdata()
              } else {
                this.$message.error(res.errorMsg);
              }
            })
          } else {
            add({Company_id, Position_id, Department_id, Name, Phone, Gender, Password}).then(res => {
              this.confirmLoading = false;
              if (res.errorCode == 200) {
                this.$message.success(res.payload);
                this.handleCancel()
                this.$parent.getdata()
              } else {
                this.$message.error(res.errorMsg);
              }
            })
          }
        }
      })
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ql-container {
  height: 400px;
}
</style>