import request from '../../utils/request';

//查询
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Admin/Position/find',
        method: 'get',
        params: query
    });
};

//查询部门
export const fetchDepartment = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Admin/Position/find/Department',
        method: 'get',
        params: query
    });
};

//添加
export const add = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Admin/Position/add',
        method: 'post',
        data: query
    });
};

//编辑
export const edit = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Admin/Position/edit',
        method: 'post',
        data: query
    });
};


//删除
export const deleteData = id => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Admin/Position/delete/' + id,
        method: 'delete',
    });
};
