<template>
  <div class="Object">
    <div class="topBlock"></div>
    <a-table :columns="columns" :data-source="data"
             @change="tablePaginationChange"
             :rowKey="(record, index) => { return index }"
             class="table"
             :pagination="pagination"
             :scroll="{ y: 600 }"
             :loading="loading">
         <span slot="InShow" slot-scope="text">
           <a-badge v-if="text=='已处理'" status="success" text="已处理"/>
           <a-badge v-if="text=='无法处理'" status="error" text="无法处理"/>
          <a-badge v-if="text=='待处理'" color="orange" text="待处理"/>
        </span>
      <span slot="Operate" slot-scope="text,record">
       <a-button icon="file-text" size="small" class="button" type="primary" @click="opanText(record.id)"
                 ghost>反馈内容</a-button>
       <a-button icon="right-circle" size="small" class="button" type="primary" @click="opanEditInShow(record.id)" ghost v-if="record.InShow=='待处理'">标记处理</a-button>
     </span>
    </a-table>
    <EditInShow ref="EditInShow"></EditInShow>
  </div>
</template>

<script>
import indexTest from "./moble/indexTest";
import {fetchData} from "@/api/Operate/Feedback";
import EditInShow from './com/EditInShow.vue'


let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    opanEditInShow(id){
      const commodity = this.data.find(item => item.id == id);
      this.$refs.EditInShow.open(commodity)
    },
    opanText(id) {
      const commodity = this.data.find(item => item.id == id);

      this.$info({
        title: '反馈内容',
        content: commodity.Text,
        icon: 'file',
        okText: '关闭',
      });
    },
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.data = []
      this.loading = true
      let pageIndex = this.pagination.current
      let pageSize = this.pagination.pageSize
      fetchData({pageIndex, pageSize}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data
          for (let i in data) {
            let time = data[i].NewTime
            let day = new Date(time);
            data[i].NewTime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          }
          this.data = data
          this.pagination.total = res.payload.total
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    }
  },
  components:{EditInShow}
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }


  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }
}

.table {
  width: 98%;
  margin: 0 auto;
}

.green {
  color: green;
}

.red {
  background-color: red;
}

.button {
  display: inline-block;
  margin-right: 15px;
}


</style>
