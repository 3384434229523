import request from '../../utils/request';



//查询部门
export const fetchDepartment = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Admin/User/find/Department',
        method: 'get',
        params: query
    });
};

//查询职务
export const fetchPosition = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Admin/User/find/Position',
        method: 'get',
        params: query
    });
};

//查询
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Business/Internal/find',
        method: 'get',
        params: query
    });
};


//添加
export const add = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Business/Internal/add',
        method: 'post',
        data: query
    });
};

//编辑
export const edit = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Business/Internal/edit',
        method: 'post',
        data: query
    });
};


//删除
export const deleteData = id => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Business/Internal/delete/' + id,
        method: 'delete',
    });
};

//更新状态
export const upSetInShow = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Business/Internal/upSetInShow',
        method: 'post',
        data: query
    });
};