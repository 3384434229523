var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-card',{staticClass:"content-card",attrs:{"headStyle":{'background': '#f3f3f3', 'width': '100%'},"bodyStyle":{'padding': '0px'}}},[_c('div',{staticClass:"card-title",attrs:{"slot":"title"},slot:"title"},[_c('div',[_c('a-icon',{staticStyle:{"font-size":"14px","margin-right":"5px"},attrs:{"type":"printer"}}),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("打印说明")])],1)]),_c('div',{staticClass:"content-print-info"},[_c('a-list',{attrs:{"item-layout":"horizontal","data-source":_vm.infoData},scopedSlots:_vm._u([{key:"renderItem",fn:function(item, index){return _c('a-list-item',{},[_c('a-list-item-meta',{attrs:{"description":index + 1 + '. ' + item}})],1)}}])})],1)]),_c('a-card',{staticClass:"content-card",attrs:{"headStyle":{'background': '#f3f3f3', 'width': '100%'},"bodyStyle":{'padding': '0px'}}},[_c('div',{staticClass:"card-title",attrs:{"slot":"title"},slot:"title"},[_c('div',[_c('a-icon',{staticStyle:{"font-size":"14px","margin-right":"5px"},attrs:{"type":"printer"}}),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("标签信息")])],1)]),_c('div',{staticClass:"content-form"},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"标签数量"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'tagNum',
                              {  rules: [{ required: true, message: '请输入标签数量' }],initialValue:1 },
                          ]),expression:"[\n                              'tagNum',\n                              {  rules: [{ required: true, message: '请输入标签数量' }],initialValue:1 },\n                          ]"}]})],1),_c('a-form-item',{attrs:{"label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"label":"重复打印次数"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'duplicateNum',
                              {  rules: [{ required: false, message: '请输入重复标签数量(默认为4)' }],initialValue:1 },
                          ]),expression:"[\n                              'duplicateNum',\n                              {  rules: [{ required: false, message: '请输入重复标签数量(默认为4)' }],initialValue:1 },\n                          ]"}]})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.isPrinting},on:{"click":_vm.test}},[_vm._v(" 打印标签 ")])],1)],1)],1)]),_c('canvas',{ref:"canvas",staticClass:"qr"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }