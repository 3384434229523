
export default {
    columns:[
        {
            title: '维保编号',
            dataIndex: 'Order_id',
            key: 'Order_id',
            width: 150,
        },
        {
            title: '所属业务员',
            dataIndex: 'Business_id',
            key: 'Business_id',
            scopedSlots: { customRender: 'Business_id' },
        },
        {
            title: '客户账号',
            dataIndex: 'User_Phone',
            key: 'User_Phone',
        },
        {
            title: '状态',
            dataIndex: 'InShow',
            key: 'InShow',
            scopedSlots: { customRender: 'InShow' },
        },
        {
            title: '提交时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
            width: 220,
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
            width: 250,
        },
    ]
}
