
export default {
    columns:[
        {
            title: '姓名',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '手机号',
            key: 'Phone',
            dataIndex: 'Phone',
        },
        {
            title: '询价订单',
            key: 'inquiry_Number',
            dataIndex: 'inquiry_Number',
        },
        {
            title: '维修订单',
            key: 'maintenance_Number',
            dataIndex: 'maintenance_Number',
        },
        {
            title: '新建时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
            width: 200,
        },
        {
            title: '登录时间',
            key: 'LoginTime',
            dataIndex: 'LoginTime',
            width: 200,
        },

        // {
        //     title: '操作',
        //     key: 'Operate',
        //     dataIndex: 'Operate',
        //     scopedSlots: { customRender: 'Operate' },
        //     width: 250,
        // },
    ]
}
