//测试权限
export const RouterData = () => {
    return[
        {
            icon:'menu',
            title: '快捷统计' ,
            item_link:'/Statistics'
        },
        {
            icon:'file-text',
            title: '客户管理' ,
            item_link:'/Custom'
        },
        {
            icon:'user',
            title: '业务员管理',
            item_link:'/Business'
        },
        {
            icon:'inbox',
            title: '运营管理',
            item_link:'/Operate'
        },
        {
            icon:'shopping',
            title: '管理员管理',
            item_link:'/Admin'
        },
        {
            icon:'setting',
            title: '系统管理',
            item_link:'/SetUp'
        }
    ]
}

