<template>
  <div>
    <a-modal
        title="选择业务员"
        :visible="visible"
        :footer="null"
        @cancel="handleCancel"
        :width="1200"
    >
      <a-table :columns="columns" :data-source="data"
               :rowKey="(record, index) => { return index }"
               class="table"
               :pagination="pagination"
               :loading="loading">
        <span slot="Operate" slot-scope="tags,record">
             <a-button type="link" @click="add(record)">选择</a-button>
        </span>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import DistData from "./addCommittee.js";
import {addBusiness, FindBusiness} from "@/api/Custom/Maintenance";

let {columns} = DistData
export default {
  data() {
    return {
      columns,
      visible: false,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
    };
  },
  methods: {
    add(record) {
      let id = this.$route.query.id
      let Business_id = record.id
      addBusiness({id,Business_id}).then(res => {
        if (res.errorCode == 200) {
          this.$message.success(res.payload);
          this.handleCancel()
          this.$parent.init()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    open() {
      this.visible = true;
      this.getdata()
    },
    getdata() {
      let pageIndex = this.pagination.current
      let pageSize = this.pagination.pageSize
      this.loading = true
      FindBusiness({pageIndex, pageSize}).then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data
          this.pagination.total = res.payload.total
          for (let i in data) {
            let time = data[i].NewTime
            let day = new Date(time);
            data[i].NewTime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          }
          this.data = data

        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    handleCancel() {
      this.visible = false;
      this.data = []
    },
  },
};
</script>
