<template>
  <div>
    <a-modal
        :title="isEdit ? '编辑职务' : '添加职务'"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        width="500px"
    >
      <a-form :form="form">
        <a-form-item label="部门">
          <a-select
              :disabled="isEdit"
              @change="handleChange"
              v-decorator="[
                 'Department_id',
                  {rules: [{ required: true, message: '请选择部门' }] , initialValue: isEdit ? commodityDetail.Department_Name : ''},
              ]">
            <a-select-option :value="item.id" v-for="(item,index) in DepartmentData" :key="index">
              {{ item.Name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="职务昵称">
          <a-input
              placeholder="请输入职务昵称"
              v-decorator="[
                  'Name',
                  {rules: [{ required: true, message: '请输入职务昵称' }],initialValue: isEdit ? commodityDetail.Name : '' },
              ]"/>
        </a-form-item>
        <a-form-item label="描述">
          <a-input
              placeholder="请输入描述"

              v-decorator="[
                 'DescribeText',
              {  rules: [{  message: '请输入描述' }],initialValue: isEdit ? commodityDetail.DescribeText : '' },
          ]"/>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import {add, edit, fetchDepartment} from "@/api/Admin/Position";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      DepartmentData: [],
      commodityDetail: {}
    };
  },
  methods: {
    handleChange(value) {
      this.form.setFieldsValue({
        Department_id: value
      })
    },
    //打开
    open(commodityDetail) {
      this.visible = true;
      this.form.resetFields()
      this.commodityDetail = commodityDetail
      this.init()
    },
    init() {
      fetchDepartment().then(res => {
        if (res.errorCode == 200) {
          this.DepartmentData = res.payload
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.confirmLoading = true;

          let Department_id = values.Department_id
          let Name = values.Name
          let DescribeText = values.DescribeText

          if (this.isEdit) {
            let id = this.commodityDetail.id
            edit({id, Name, DescribeText}).then(res => {
              this.confirmLoading = false
              if (res.errorCode == 200) {
                this.$message.success(res.payload);
                this.handleCancel()
                this.$parent.getdata()
              } else {
                this.$message.error(res.errorMsg);
              }
            })
          } else {
            add({Department_id, Name, DescribeText}).then(res => {
              this.confirmLoading = false
              if (res.errorCode == 200) {
                this.$message.success(res.payload);
                this.handleCancel()
                this.$parent.getdata()
              } else {
                this.$message.error(res.errorMsg);
              }
            })
          }
        }
      })
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">

</style>