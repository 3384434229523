<template>
  <div>
    <a-modal
        :title="isEdit ? '编辑公告' : '添加公告'"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        width="900px"
    >
      <a-form :form="form">
        <a-form-item label="公告标题">
          <a-input
              placeholder="请输入公告标题"
              v-decorator="[
                                'Title',
                                {  rules: [{ required: true, message: '请输入公告标题' }] ,initialValue: isEdit ? commodityDetail.Title : '' },
                            ]"
          />
        </a-form-item>
        <a-form-item label="公告类型">
          <a-input
              placeholder="请输入公告类型"
              v-decorator="[
                                'Class',
                                {  rules: [{ required: true, message: '请输入公告类型' }] ,initialValue: isEdit ? commodityDetail.Class : '' },
                            ]"
          />
        </a-form-item>
        <a-form-item label="发布人">
          <a-input
              placeholder="请输入发布人"
              v-decorator="[
                                'AdminName',
                                {  rules: [{ required: true, message: '请输入发布人' }]  ,initialValue: isEdit ? commodityDetail.AdminName : ''},
                            ]"
          />
        </a-form-item>
      </a-form>
      <div class="title"><i style="color: red;margin-right: 5px">*</i>公告详情</div>
      <quill-editor v-model="content" style="height: 500px;display: block;margin-top: 20px"></quill-editor>
      <div style="width: 100%;height: 80px"></div>
    </a-modal>
  </div>
</template>
<script>

import {quillEditor} from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import indexTest from "../moble/indexTest";
import {add, edit} from "@/api/Operate/Notice";

let {editorOption} = indexTest
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      content: '',
      editorOption,
      commodityDetail: {}
    };
  },
  components: {quillEditor},
  methods: {
    //打开
    open(commodity) {
      this.visible = true;
      this.form.resetFields()
      this.content = ''
      this.commodityDetail = commodity
      if (commodity) {
        this.content = commodity.Text
      }
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let Title = values.Title
          let Class = values.Class
          let AdminName = values.AdminName
          let Text = this.content

          this.confirmLoading = true;

          if (this.isEdit) {
            let id = this.commodityDetail.id
            edit({id, Title, Class, AdminName, Text}).then(res => {
              this.confirmLoading = false
              if (res.errorCode == 200) {
                this.$message.success(res.payload);
                this.handleCancel()
                this.$parent.getdata()
              } else {
                this.$message.error(res.errorMsg);
              }
            })
          } else {
            add({Title, Class, AdminName, Text}).then(res => {
              this.confirmLoading = false
              if (res.errorCode == 200) {
                this.$message.success(res.payload);
                this.handleCancel()
                this.$parent.getdata()
              } else {
                this.$message.error(res.errorMsg);
              }
            })
          }


        }
      })
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ql-container {
  height: 400px;
}
</style>