<template>
<div class="Details">
  <a-button type="link" style="margin-bottom: 10px" @click="black">返回上一级</a-button>
  <div class="Info">
    <div class="id">询价编号：202062976600</div>
    <div class="data">
      <a-row>
        <a-col :span="6" class="text">
          客户来源：抖音投流
        </a-col>
        <a-col :span="6" class="text">
          所属业务员：XXX
        </a-col>
        <a-col :span="6" class="text">
          提交时间:2023-11-25 23:26:08
        </a-col>
        <a-col :span="6" class="text">
          询价状态: <a-badge status="warning" text="待处理" style="margin-left: 10px"/>
        </a-col>
      </a-row>
    </div>
  </div>
  <div class="Info" style="padding-top: 70px">
    <a-steps :current="1" style="width: 90%;margin: 0 auto;" progress-dot>
      <a-step>
        <template slot="title">
          询价提交
        </template>
        <span slot="description">2020-10-14 12:00</span>
      </a-step>
      <a-step>
        <template slot="title">
          业务员送达
        </template>
        <span slot="description">2020-10-14 12:00</span>
      </a-step>
      <a-step>
        <template slot="title">
          标记处理
        </template>
        <span slot="description">2020-10-14 12:00</span>
      </a-step>

    </a-steps>
  </div>
  <div class="Info" style="height: 280px;padding-top: 70px">
    <a-descriptions title="客户信息" bordered style="width: 90%;margin: 0 auto" :column="{ xxl: 4}">
      <a-descriptions-item label="姓名">
        XXXX
      </a-descriptions-item>
      <a-descriptions-item label="公司名称">
        XXX公司
      </a-descriptions-item>
      <a-descriptions-item label="提交时间">
        2018-04-24 18:00:00
      </a-descriptions-item>
      <a-descriptions-item label="手机号码">
        190000000
      </a-descriptions-item>
    </a-descriptions>
  </div>
</div>
</template>

<script>
  export default {
    methods:{
      black(){
        window.history.go(-1);
      }
    }
  }
</script>

<style scoped lang="scss">
.Details{
  width: 100%;

}
.Info{
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 10px;
  .id{
    position: absolute;
    left: 50px;
    top: 40px;
    color: #666666;
    font-size: 20px;
    font-weight: bold;
  }
  .data{
    position: absolute;
    width: 100%;
    height: 90px;
    line-height: 90px;
    top: 110px;
    font-size: 15px;
    .text{
      text-align: center;
    }
  }
}
</style>