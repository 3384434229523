import request from '../../utils/request';

//查询
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Admin/Company/find',
        method: 'get',
        params: query
    });
};

//添加
export const add = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Admin/Company/add',
        method: 'post',
        data: query
    });
};

//删除
export const deleteData = id => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Admin/Company/delete/' + id,
        method: 'delete',
    });
};
