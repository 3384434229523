import { render, staticRenderFns } from "./Salesman.vue?vue&type=template&id=831fa47c&scoped=true"
import script from "./Salesman.vue?vue&type=script&lang=js"
export * from "./Salesman.vue?vue&type=script&lang=js"
import style0 from "./Salesman.vue?vue&type=style&index=0&id=831fa47c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "831fa47c",
  null
  
)

export default component.exports