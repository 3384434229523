<template>
  <div>
    <a-modal
        title="添加公司"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        width="500px"
    >
      <a-form :form="form">
        <a-form-item label="公司昵称">
          <a-input
              placeholder="请输入公司昵称"
              v-decorator="[
                                'Name',
                                {  rules: [{ required: true, message: '请输入公司昵称' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="公司负责人姓名">
          <a-input
              placeholder="请输入公司负责人姓名"
              v-decorator="[
                                'UserName',
                                {  rules: [{ required: true, message: '请输入公司负责人姓名' }] },
                            ]"
          />
        </a-form-item>
        <a-form-item label="负责人联系方式">
          <a-input
              placeholder="请输入负责人联系方式"
              v-decorator="[
                                'UserPhone',
                                {  rules: [{ required: true, message: '请输入负责人联系方式' }] },
                            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>

</template>
<script>

import {add} from "@/api/Admin/Company";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
    };
  },
  methods: {
    //打开
    open() {
      this.visible = true;
      this.form.resetFields()
    },
    handleOk() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let Name = values.Name
          let UserName = values.UserName
          let UserPhone = values.UserPhone
          this.confirmLoading = true;
          add({Name,UserName,UserPhone}).then(res => {
            this.confirmLoading = false
            if (res.errorCode == 200) {
              this.$message.success(res.payload);
              this.handleCancel()
              this.$parent.getdata()
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      })
    },
    handleCancel() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ql-container {
  height: 400px;
}
</style>