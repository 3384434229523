<template>
  <a-spin :spinning="loading">
  <div class="Custom">
    <div class="nav">平台获客渠道分析</div>
    <div class="main">
      <div id="container_3"></div>
    </div>
  </div>
  </a-spin>
</template>

<script>
import {Column} from '@antv/g2plot';
import {findPlatform} from "@/api/Statistics/Visualization";

export default {
  data() {
    return {
      loading:false,
      data: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading=true
      findPlatform().then(res => {
        this.loading=false
        if (res.errorCode == 200) {
          let data = res.payload
          let inquirySum = data.inquirySum
          let maintenance = data.maintenance
          let sum = [...inquirySum, ...maintenance]

          this.data = sum
          this.canvasInit()
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    canvasInit() {
      let data = this.data
      const piePlot = new Column('container_3', {
        data,
        isGroup: true,
        xField: '月份',
        yField: '新增订单量',
        seriesField: 'name',
        /** 设置颜色 */
        color: ['#45d9d9', '#ff8e9d'],
        /** 设置间距 */
        // marginRatio: 0.1,
        label: {
          // 可手动配置 label 数据标签位置
          position: 'middle', // 'top', 'middle', 'bottom'
          // 可配置附加的布局方法
          layout: [
            // 柱形图数据标签位置自动调整
            {type: 'interval-adjust-position'},
            // 数据标签防遮挡
            {type: 'interval-hide-overlap'},
            // 数据标签文颜色自动调整
            {type: 'adjust-color'},
          ],
        },
      });

      piePlot.render();
    }
  }
}
</script>

<style scoped lang="scss">
@mixin ranking {
  position: relative;
  background-color: #fff;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  .nav {
    color: #666666;
    width: 100%;
    height: 55px;
    line-height: 55px;
    font-size: 13px;
    font-weight: bold;
    background-color: #f9f9f9;
    text-indent: 1rem;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    border: 1px solid #e9e9e9;
  }
  .main {
    padding: 20px;
    height: 345px;
  }
}

.Custom {
  width: 95%;
  height: 400px;
  margin: 0 auto;
  margin-bottom: 20px;
  @include ranking;
  position: relative;

  #container_3 {
    width: 95%;
    height: 300px;
  }
}
</style>