import request from '../../utils/request';

//查询基础信息
export const findMain = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Statistics/Visualization/find/main',
        method: 'get',
        params: query
    });
};


//查询当月排行信息
export const findCustom_user = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Statistics/Visualization/find/custom_user',
        method: 'get',
        params: query
    });
};

//查询当月信息
export const findBusiness = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Statistics/Visualization/find/business',
        method: 'get',
        params: query
    });
};

//查询平台趋势分析（询价、维保）
export const findPlatform = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Statistics/Visualization/find/platform',
        method: 'get',
        params: query
    });
};

//查询平台趋势分析（业务员）
export const findPlatformBusiness = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Statistics/Visualization/find/platform/business',
        method: 'get',
        params: query
    });
};