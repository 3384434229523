<template>
  <div>
    <a-modal
        title='设置状态'
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        width="500px"
    >
      <a-form :form="form">
        <a-form-item>
          <a-radio-group
              v-decorator="[
                 'InShow',
                  {rules: [{ required: true, message: '请选择状态' }] , initialValue:commodityDetail.InShow},
              ]"
          >
            <a-radio value="正常">正常</a-radio>
            <a-radio value="禁用">禁用</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {upSetInShow} from "@/api/Business/Internal";

export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      commodityDetail: {}
    }
  },
  methods: {
    open(commodityDetail) {
      this.visible = true;
      this.commodityDetail = commodityDetail
    },
    handleOk() {
      let id = this.commodityDetail.id

      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.confirmLoading = true;
          let InShow = values.InShow
          upSetInShow({id, InShow}).then(res => {
            this.confirmLoading = false
            if (res.errorCode == 200) {
              this.$message.success(res.payload);
              this.handleCancel()
              this.$parent.getdata()
            } else {
              this.$message.error(res.errorMsg);
            }
          })
        }
      })

    },
    handleCancel() {
      this.visible = false;
    },
  }
}
</script>

<style scoped lang="scss">

</style>