var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":_vm.isEdit ? '编辑公告' : '添加公告',"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"width":"900px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"公告标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Title',
                              {  rules: [{ required: true, message: '请输入公告标题' }] ,initialValue: _vm.isEdit ? _vm.commodityDetail.Title : '' },
                          ]),expression:"[\n                              'Title',\n                              {  rules: [{ required: true, message: '请输入公告标题' }] ,initialValue: isEdit ? commodityDetail.Title : '' },\n                          ]"}],attrs:{"placeholder":"请输入公告标题"}})],1),_c('a-form-item',{attrs:{"label":"公告类型"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'Class',
                              {  rules: [{ required: true, message: '请输入公告类型' }] ,initialValue: _vm.isEdit ? _vm.commodityDetail.Class : '' },
                          ]),expression:"[\n                              'Class',\n                              {  rules: [{ required: true, message: '请输入公告类型' }] ,initialValue: isEdit ? commodityDetail.Class : '' },\n                          ]"}],attrs:{"placeholder":"请输入公告类型"}})],1),_c('a-form-item',{attrs:{"label":"发布人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                              'AdminName',
                              {  rules: [{ required: true, message: '请输入发布人' }]  ,initialValue: _vm.isEdit ? _vm.commodityDetail.AdminName : ''},
                          ]),expression:"[\n                              'AdminName',\n                              {  rules: [{ required: true, message: '请输入发布人' }]  ,initialValue: isEdit ? commodityDetail.AdminName : ''},\n                          ]"}],attrs:{"placeholder":"请输入发布人"}})],1)],1),_c('div',{staticClass:"title"},[_c('i',{staticStyle:{"color":"red","margin-right":"5px"}},[_vm._v("*")]),_vm._v("公告详情")]),_c('quill-editor',{staticStyle:{"height":"500px","display":"block","margin-top":"20px"},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('div',{staticStyle:{"width":"100%","height":"80px"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }