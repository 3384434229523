
export default {
    columns_1:[
        {
            title: '类型',
            dataIndex: 'Types',
            key: 'Types',
            width: 80,
            scopedSlots: { customRender: 'Types' },
        },
        {
            title: '数量',
            dataIndex: 'Number',
            key: 'Number',
            width: 40,
        },
        {
            title: '占比',
            dataIndex: 'Proportion',
            key: 'Proportion',
            width: 40,
        },
    ],
    columns_2:[
        {
            title: '排名',
            dataIndex: 'id',
            key: 'id',
            width: 20,
            scopedSlots: { customRender: 'id' },
        },
        {
            title: '业务员',
            dataIndex: 'Name',
            key: 'Name',
            width: 40,
        },
        {
            title: '客户订单数',
            dataIndex: 'Custom_Number',
            key: 'Custom_Number',
            width: 40,
        }
    ],
    columns_3:[
        {
            title: '排名',
            dataIndex: 'id',
            key: 'id',
            width: 20,
            scopedSlots: { customRender: 'id' },
        },
        {
            title: '地区',
            dataIndex: 'Address',
            key: 'Address',
            width: 40,
        },
        {
            title: '增加客户数',
            dataIndex: 'NewUser',
            key: 'NewUser',
            width: 40,
        },
        {
            title: '总客户数',
            dataIndex: 'Custom_user_Tol',
            key: 'Custom_user_Tol',
            width: 40,
        },
        {
            title: '人数占比',
            dataIndex: 'Percentage',
            key: 'Percentage',
            width: 40,
        },
    ]
}
