<template>
  <a-layout>
    <a-layout-sider class="sub-sider">
      <sub-sider :menu="menu" :title="menuTitle"/>
    </a-layout-sider>
    <a-layout-content>
      <content-header :title="contentTitle"/>
      <div class="content">
        <router-view></router-view>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import SubSider from '@/components/SubSider.vue';
import ContentHeader from '@/components/ContentHeader.vue';

export default {
  components: {
    'sub-sider': SubSider,
    'content-header': ContentHeader,
  },
  data() {
    return {
      menuTitle: '快捷菜单',
      contentTitle: "快捷菜单",
      menu: [
        {
          icon:'line-chart',
          title: '数据统计',
          item_link:'/Statistics/Visualization'
        },
        // {
        //   icon:'file',
        //   title: '用户反馈',
        //   item_link:'/Operate/Feedback'
        // },
      ]
    };
  },
  methods: {},
}
</script>

<style scoped>

.sub-sider {
  overflow: auto;
  height: 100vh;
  background: #ebedf1;
}

.content {
  background: #f0f2f5;
  padding: 20px;
}

</style>