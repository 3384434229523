<template>
  <div>
    <a-card class="content-card" :headStyle="{'background': '#f3f3f3', 'width': '100%'}"
            :bodyStyle="{'padding': '0px'}">
      <div slot="title" class="card-title">
        <div>
          <a-icon type="printer" style="font-size: 14px; margin-right: 5px;"/>
          <span style="font-size: 14px;">打印说明</span>
        </div>
      </div>
      <div class="content-print-info">
        <a-list item-layout="horizontal" :data-source="infoData">
          <a-list-item slot="renderItem" slot-scope="item, index">
            <a-list-item-meta :description="index + 1 + '. ' + item"></a-list-item-meta>
          </a-list-item>
        </a-list>
      </div>
    </a-card>
    <a-card class="content-card" :headStyle="{'background': '#f3f3f3', 'width': '100%'}"
            :bodyStyle="{'padding': '0px'}">
      <div slot="title" class="card-title">
        <div>
          <a-icon type="printer" style="font-size: 14px; margin-right: 5px;"/>
          <span style="font-size: 14px;">标签信息</span>
        </div>
      </div>
      <div class="content-form">
        <a-form :form="form">
          <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="标签数量"
          >
            <a-input-number
                v-decorator="[
                                'tagNum',
                                {  rules: [{ required: true, message: '请输入标签数量' }],initialValue:1 },
                            ]"
            />
          </a-form-item>
          <a-form-item
              :label-col="formItemLayout.labelCol"
              :wrapper-col="formItemLayout.wrapperCol"
              label="重复打印次数"
          >
            <a-input-number
                v-decorator="[
                                'duplicateNum',
                                {  rules: [{ required: false, message: '请输入重复标签数量(默认为4)' }],initialValue:1 },
                            ]"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
            <a-button type="primary" @click="test" :disabled="isPrinting">
              打印标签
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-card>
    <!--    <canvas id="barcode"></canvas>-->
    <canvas ref="canvas" class="qr"></canvas>

  </div>
</template>


<script>
const formItemLayout = {
  labelCol: {span: 5},
  wrapperCol: {span: 19},
};

// function centimetersToPixels(centimeters, dpi) {
//   const inches = centimeters / 2.54;
//   const pixels = inches * dpi;
//   return pixels;
// }

import JsBarcode from 'jsbarcode';
// import {AddSampleRecord, findHospital} from "@/api/Printer/Print";

export default {
  name: "PrintCode",
  props: {},
  data() {
    return {
      selectedRowKeys: [],
      form: this.$form.createForm(this),
      formItemLayout,
      selected_device: null,
      infoData: [
        "保持手持打印标签机处于开机状态",
        "保持手持打印标签机处于开机状态",
        "保持手持打印标签机处于开机状态"
      ],
      loginStates: this.$store.state.user.info,
      isPrinting: false,
      hospital: [],

      width: 106,
      height: 51
      // printProcess: 0
    }
  },
  mounted() {
    this.initPrinter();
  },
  methods: {
    handlePrint(selected_device, uri) {
      return new Promise((resolve, reject) => {
        selected_device.convertAndSendFile(uri, resolve, reject);
      });
    },
    async test() {
      if (!this.selected_device) {
        this.$message.error("打印设备未就绪！");
        return;
      }

      //生成初始化
      let canvas = this.$refs.canvas
      canvas.width = 500
      canvas.height = 350

      const options = {
        format: 'CODE128', // 条形码格式
        width: 2, // 条形码宽度
        fontSize: 16, // 条形码字体大小
        height: 60, // 条形码高度
        displayValue: true, // 是否显示条形码数据
        text: 2024041800001,
        marginTop: 30,
        marginBottom: 30,
        marginLeft: 30,
        marginRight: 30,
      };

      JsBarcode(canvas, "20240418055555", options);

      let imgBase64 = canvas.toDataURL("image/png")
      await this.handlePrint(this.selected_device, imgBase64);
    },


    initPrinter() {
      const that = this;
      window.BrowserPrint.getDefaultDevice("printer", function (device) {
        console.log(device);
        that.selected_device = device;
      }, function (error) {
        that.$message.error("初始化打印设备失败: ", error);
      })
      // window.BrowserPrint.getApplicationConfiguration(function(config){
      //     console.log(JSON.stringify(config))
      // }, function(error){
      //     console.log(error);
      // })
    },
  }
}
</script>

<style scoped>
.content-card {
  width: 100%;
  overflow: auto;
  margin-bottom: 20px;
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-print-info {
  padding: 0 20px;
}

.content-form {
  padding-top: 20px;
}

.qr {
  //width: 400px;
}
</style>