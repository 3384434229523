<template>
  <div class="Object">
    <a-button style="margin-bottom: 10px;margin-top: 15px;margin-left: 15px" @click="openAdd">添加职务</a-button>
    <a-table :columns="columns" :data-source="data"
             :rowKey="(record, index) => { return index }"
             class="table"
             :scroll="{ y: 600 }"
             :loading="loading">
     <span slot="Operate" slot-scope="text,record">
       <a-button icon="setting" size="small" class="button" type="primary" @click="opanObject(record.id)"
                 ghost>设置权限</a-button>
       <a-button icon="edit" size="small" class="button" type="primary" @click="edit(record.id)"
                 ghost>编辑职务</a-button>
       <a-button icon="delete" size="small" class="button" type="danger" @click="deletes(record.id)"
                 ghost>删除</a-button>
     </span>
    </a-table>
    <Add ref="Add" :isEdit="false"></Add>
    <Add ref="Edit" :isEdit="true" :commodityDetail="commodityDetail"></Add>
  </div>
</template>

<script>
import indexTest from "./moble/indexTest";

let {columns} = indexTest
import Add from './com/Add.vue'
import {fetchData,deleteData} from "@/api/Admin/Position";

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
      commodityDetail:{}
    }
  },
  components: {Add},
  mounted() {
    this.getdata()
  },
  methods: {
    edit(id){
      const commodity = this.data.find(item => item.id == id);
      this.$refs.Edit.open(commodity);
    },
    deletes(id){
      let that = this
      this.$confirm({
        title: '是否删除该部门吗?',
        content: () => <div style="color:red;">注意：删除职位操作会同步删除员工数据，请小心操作！</div>,
        onOk() {
          deleteData(id).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        },
      });
    },
    getdata(){
      this.data = []
      this.loading = true
      let pageIndex = this.pagination.current
      let pageSize = this.pagination.pageSize
      fetchData({pageIndex, pageSize}).then(res=>{
        this.loading = false
        if (res.errorCode == 200) {
          let data=res.payload.data
          for (let i in data) {
            let time = data[i].NewTime
            let day = new Date(time);
            data[i].NewTime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
          }
          this.data = data
          this.pagination.total = res.payload.total
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    openAdd() {
      this.$refs.Add.open()
    },
    opanObject() {
      this.$router.push('/Business/Details')
    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }


  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }
}

.table {
  width: 98%;
  margin: 0 auto;
}

.green {
  color: green;
}

.red {
  background-color: red;
}

.button {
  display: inline-block;
  margin-right: 15px;
}


</style>
