<template>
  <div class="Login">

    <div class="bg"></div>
    <div class="main">
      <div class="logo">
        <img src="/images/logo.png" alt="logo"/>
      </div>
      <div class="title">实验室小助手信息管理平台</div>
      <a-form :form="form">
        <a-form-item>
          <a-input
              size="large"
              placeholder="请输入账号"
              v-decorator="[
                            'Phone',
                            {  rules: [{ required: true, message: '请输入账号' }] }
             ]"
          >
            <a-icon slot="prefix" type="user"/>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input-password
              size="large"
              placeholder="请输入密码"
              v-decorator="[
                            'Password',
                             {  rules: [{ required: true, message: '请输入密码' }] }
                        ]">
            >
            <a-icon slot="prefix" type="lock"/>
          </a-input-password>
        </a-form-item>
        <a-form-item>
          <a-input
              class="CodeInput"
              size="large"
              placeholder="请输入校验码"
              v-decorator="[
                            'Code',
                            {  rules: [{ required: true, message: '请输入校验码' }] }
             ]"
          >
            <a-icon slot="prefix" type="safety"/>
          </a-input>
          <div class="Code" @click="init">
            <canvas id="captchaCanvas"></canvas>
          </div>
        </a-form-item>
      </a-form>
      <div class="nav">
        <a-checkbox @change="onChange">
          记住密码
        </a-checkbox>
        <div class="right">
          <a-button type="link" @click="openForget">
            忘记密码
          </a-button>
        </div>
      </div>
      <a-button block class="go-login" @click="Login" size="large">
        登录
      </a-button>
    </div>
    <forget ref="forget"></forget>
    <add ref="add"></add>
    <selectCompany ref="selectCompany"></selectCompany>
  </div>
</template>

<script>
import forget from "@/pages/Login/com/forget";
import add from "@/pages/Login/com/add";
import selectCompany from './com/select.vue'
import {FindCheck} from "@/api/Login";

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      code: ''
    }
  },
  mounted() {
    this.autoPsthInfo()
    this.init()
  },
  methods: {
    init() {
      const canvas = document.getElementById('captchaCanvas');
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#f9f9f9';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.font = '50px sans-serif';
      ctx.fillStyle = '#000';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const text = this.createCaptcha();
      this.code = text
      ctx.fillText(text, canvas.width / 2, canvas.height / 2);
      for (let i = 0; i < 5; i++) {
        ctx.beginPath();
        ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
        ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
        ctx.strokeStyle = '#ddd';
        ctx.stroke();
      }
    },
    createCaptcha() {
      const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let captcha = '';
      for (let i = 0; i < 6; i++) {
        captcha += chars[Math.floor(Math.random() * chars.length)];
      }
      console.log(captcha);
      return captcha;
    },
    //检查是否存在账号状态自动登录
    autoPsthInfo() {
      let token = localStorage.getItem("access-token");

      if (token) {
        console.log(token)
      }
    },
    Login() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let Phone = values.Phone
          let Password = values.Password
          let Code = values.Code
          let code = this.code
          console.log(code)
          // if (code.toLowerCase() != Code.toLowerCase()) {
          //   this.$message.error('验证码错误');
          //   return
          // }

          FindCheck({Phone,Password}).then(res=>{
            if (res.errorCode == 200) {
              let info = res.payload

              this.$store.state.user.info = info

              localStorage.setItem('info', JSON.stringify(info));

              this.$message.success('登录成功');
              setTimeout(() => {
                this.$router.push('/')
              }, 200)
            }else {
              this.$message.error(res.errorMsg);
            }
          })


          console.log(this.code)
          console.log(Phone, Password, Code)
          // this.$router.push('/Visualization')
        }
      })
    },
    openForget() {
      this.$refs.forget.open()
    },
    openAdd() {
      this.$refs.add.open()
    },
    onChange() {

    },
  },
  components: {
    forget,
    add,
    selectCompany
  }
}
</script>

<style scoped lang="scss">
.Login {
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  position: relative;

  .bg {
    background-color: #333744;
    width: 100%;
    height: 400px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -200px;
    background-image: url("../../assets/bg.png");
    background-size: 100% 100%;

  }


  .main {
    width: 420px;
    height: 550px;
    padding: 20px;
    background-color: #1891ff;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -205px;
    margin-top: -275px;
    border-radius: 15px;

    .logo {
      width: 90px;
      height: 90px;
      margin: 0 auto;
      margin-bottom: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .CodeInput {
      width: 250px;
    }

    .Code {
      width: 100px;
      height: 38px;
      background-color: #ebedf1;
      position: absolute;
      right: -120px;
      top: -10px;

      canvas {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      width: 100%;
      color: #ffffff;
      margin-bottom: 20px;
    }

    .nav {
      width: 100%;
      height: 30px;
      position: relative;

      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .go-login {
      margin-top: 20px;
    }
  }
}
</style>
