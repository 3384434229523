export default {
    columns:[
        {
            title: '部门名称',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: '新增时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
            width: 200,
        },
    ]
}
