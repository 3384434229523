<template>
  <div class="Details">
    <a-button type="link" style="margin-bottom: 10px" @click="black">返回上一级</a-button>
    <a-spin :spinning="loading">
      <div class="Info">
        <div class="id">维保编号：{{ data.Order_id }}</div>
        <div class="data">
          <a-row>
            <a-col :span="6" class="text">
              客户来源：主页
            </a-col>
            <a-col :span="6" class="text">
              所属业务员：<i v-if="data.Business_id">{{ data.Business_Name }}</i><i v-else
                                                                                   style="color: brown">未分配</i>
            </a-col>
            <a-col :span="6" class="text">
              提交时间：{{ data.NewTime }}
            </a-col>
            <a-col :span="6" class="text">
              维保状态:
              <a-badge v-if="data.InShow=='已处理'" status="success" text="已处理"/>
              <a-badge v-if="data.InShow=='未处理'" status="warning" text="未处理"/>
              <a-badge v-if="data.InShow=='无法处理'" status="error" text="无法处理"/>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="Info" style="padding-top: 70px">
        <a-steps :current="current" style="width: 90%;margin: 0 auto;" progress-dot>
          <a-step>
            <template slot="title">
              询价提交
            </template>
            <span slot="description">{{ data.NewTime }}</span>
          </a-step>
          <a-step>
            <template slot="title">
              业务员送达
            </template>
            <span slot="description">{{ data.Time_Business }}<a-button v-if="!data.Time_Business" size="small"
                                                                       style="margin-top: 10px" @click="openInternal">分配业务员</a-button></span>
          </a-step>
          <a-step>
            <template slot="title">
              标记处理
            </template>
            <span slot="description">{{ data.EndTime }}<a-button v-if="data.Time_Business&&!data.EndTime" size="small"
                                                                 style="margin-top: 10px"
                                                                 @click="opensign">标记处理</a-button></span>
          </a-step>

        </a-steps>
      </div>

      <div class="Info" style="height: 1000px;padding-top: 70px">
        <div class="id">维保详情</div>
        <div class="describe">
          <div class="title">问题描述</div>
          <div class="text">{{data.Text}}</div>
          <div class="title">照片展示</div>
          <div class="img">
            <img :src="item" alt="" v-for="(item,index) in data.ImageData" @click="openImage(item)" :key="index">
          </div>
          <div class="button">提醒处理</div>
        </div>
      </div>
      <LightBox :media="media" :closable="false" v-if="lightboxVisible"></LightBox>
      <div class="close" v-if="lightboxVisible" @click="closeImage">
        <img src="../../../../../public/images/close.png" alt="">
      </div>
    </a-spin>
    <addBusiness ref="addBusiness"></addBusiness>
    <Endsign ref="Endsign"></Endsign>
  </div>
</template>

<script>
import LightBox from 'vue-image-lightbox'
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'
import addBusiness from './addBusiness.vue'
import Endsign from './Endsign.vue'
import {FindDetails} from "@/api/Custom/Maintenance";

export default {
  data() {
    return {
      lightboxVisible: false,
      media: [],
      data: {},
      current: 0,
      loading: false
    }
  },
  mounted() {
    let id = this.$route.query.id
    this.id = id
    this.init()
  },
  methods: {
    opensign() {
      let data = this.data
      this.$refs.Endsign.open(data)
    },
    openInternal() {
      this.$refs.addBusiness.open()
    },
    init() {
      this.loading = true
      let id = this.id
      FindDetails({id}).then(res => {
        this.loading = false
        let payload = res.payload
        let time = payload.NewTime
        payload.NewTime = this.getFormattedCurrentTime(time)
        let Time_Business = payload.Time_Business
        let EndTime = payload.EndTime
        if (Time_Business) {
          payload.Time_Business = this.getFormattedCurrentTime(Time_Business)
          this.current = 1
        }
        if (EndTime) {
          payload.EndTime = this.getFormattedCurrentTime(EndTime)
          this.current = 2
        }
        let ImageData = payload.ImageData
        payload.ImageData = JSON.parse(ImageData)
        console.log(payload)
        this.data = payload
      })
    },
    openImage(img) {
      this.media = [
        {
          thumb: img,
          src: img
        }
      ]
      this.lightboxVisible = true
    },
    closeImage() {
      this.lightboxVisible = false
    },
    black() {
      window.history.go(-1);
    },
    getFormattedCurrentTime(date) {
      const now = new Date(date);
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
  components: {LightBox, addBusiness, Endsign},
}
</script>

<style scoped lang="scss">
.Details {
  width: 100%;

}

.Info {
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 10px;

  .id {
    position: absolute;
    left: 50px;
    top: 40px;
    color: #666666;
    font-size: 20px;
    font-weight: bold;
  }

  .data {
    position: absolute;
    width: 100%;
    height: 150px;
    line-height: 90px;
    top: 110px;
    font-size: 15px;

    .text {
      text-align: center;
    }
  }

  .describe {
    position: absolute;
    width: 100%;
    height: 90px;
    top: 90px;
    font-size: 15px;

    .title {
      text-indent: 5rem;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .text {
      width: 80%;
      height: 400px;
      margin: 0 auto;
      border: 1px solid #e4e4e4;
      overflow-y: auto;
      padding: 10px;
      line-height: 30px;
    }

    .img {
      width: 80%;
      height: 200px;
      margin: 0 auto;
      border: 1px solid #e4e4e4;
      overflow-x: auto;
      display: flex;
      align-items: center;
      padding: 0 20px;

      img {
        width: 200px;
        margin-right: 40px;
        cursor: pointer;
      }
    }

    .button {
      width: 200px;
      height: 50px;
      line-height: 50px;
      background-color: #666666;
      color: #fff;
      margin: 0 auto;
      margin-top: 50px;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      border-radius: 10px;
      cursor: pointer;
      user-select: none;
    }
  }
}

.close {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 50px;
  top: 50px;
  z-index: 10000;
  cursor: pointer;
  transition: transform 0.3s ease;

  img {
    width: 100%;
    height: 100%
  }
}

.close:hover {
  transform: rotate(90deg); /* 当鼠标悬停时应用旋转变换 */
}
</style>