<template>
  <div class="Object">
    <div class="topBlock"></div>
    <a-row type="flex">
      <a-col flex="300px">
        <div class="nav">协议目录
          <a-button type="link" @click="openAdd">添加协议</a-button>
        </div>
        <div class="data" v-for="(item,indexs) in data" :key="indexs" @click="openIndex(indexs)">
          <span v-bind:class="{dataIndex:indexs==index}">{{ item.Title }}</span>
        </div>
      </a-col>
      <a-col flex="auto">
        <div class="main">
          <div class="title" v-if="data.length>=1">{{ data[index].Title }}</div>
          <div class="time" v-if="data.length>=1">更新时间：{{ data[index].UpTime }}
            <a-button type="link" @click="openEdit(data[index].id)">编辑</a-button>
            <a-button type="link" @click="deletes(data[index].id)" class="red">删除</a-button>
          </div>
          <div class="text" v-html="data[index].Text" v-if="data.length>=1"></div>
        </div>
      </a-col>
    </a-row>
    <Add ref="Add" :isEdit="false"></Add>
    <Add ref="Edit" :isEdit="true"></Add>
  </div>
</template>

<script>
import indexTest from "./moble/indexTest";

let {columns} = indexTest
import Add from './com/Add.vue'
import {fetchData, deleteData} from "@/api/Operate/Agreement";

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      index: 0,
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    openEdit(id) {
      const commodity = this.data.find(item => item.id == id);
      this.$refs.Edit.open(commodity);
    },
    deletes(id) {
      let that = this
      this.$confirm({
        title: '是否删除该协议吗?',
        onOk() {
          deleteData(id).then(res => {
            if (res.errorCode == 200) {
              that.$message.success(res.payload);
              that.index = 0
              that.getdata()
            } else {
              that.$message.error(res.errorMsg);
            }
          })
        },
      });
    },
    openIndex(index) {
      this.index = index
    },
    getdata() {
      fetchData().then(res => {
        if (res.errorCode == 200) {
          let data = res.payload
          if (data.length >= 1) {
            for (let i in data) {
              let time = data[i].UpTime
              let day = new Date(time);
              let getMinutes = (day.getMinutes() < 10) ? '0' + day.getMinutes() : day.getMinutes()
              let getHours = (day.getHours() < 10) ? '0' + day.getHours() : day.getHours()

              data[i].UpTime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate() + ' ' + getHours + ':' + getMinutes
            }
            this.data = data
          } else {
            this.$message.error('暂无协议数据');
          }
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    opanObject() {

    },
    openAdd() {
      this.$refs.Add.open()
    }
  },
  components: {Add}
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;
  padding: 20px;

  .nav {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #f9f9f9;
    text-indent: 2rem;
  }

  .data {
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-indent: 3rem;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;
  }

  .dataIndex {
    color: #5b8ff9;
  }

  .data:hover {
    color: #6dc8ec !important;
  }


  .main {
    width: 100%;
    height: 770px;
    position: relative;
    margin-left: 10px;
    overflow-y: auto;
    //margin-left: 10px;
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #666666;
      margin-bottom: 20px;
    }

    .time {
      color: #999999;
    }

    .text {
      position: absolute;
      width: 95%;
      line-height: 30px;
      margin-top: 20px;
      left: 2%;
    }
  }
}

.red {
  color: red;
}
</style>
