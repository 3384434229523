import request from '../../utils/request';

//查询
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Custom/Maintenance/find',
        method: 'get',
        params: query
    });
};

//查询详情
export const FindDetails = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Custom/Maintenance/find/Details',
        method: 'get',
        params: query
    });
};

//查询业务员
export const FindBusiness = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Custom/Maintenance/find/Business',
        method: 'get',
        params: query
    });
};

//分配业务员
export const addBusiness = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Custom/Maintenance/add/Business',
        method: 'post',
        data: query
    });
};

//标记处理
export const End = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Custom/Maintenance/end',
        method: 'post',
        data: query
    });
};