
export default {
    columns:[
        {
            title: '渠道编号',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '名称',
            dataIndex: 'BusinessName',
            key: 'BusinessName',
        },
        {
            title: '简介',
            dataIndex: 'InShow',
            key: 'InShow',
        },
        {
            title: '产品外链',
            dataIndex: 'UserName',
            key: 'UserName',
        },
        {
            title: '备注',
            key: 'CompanyName',
            dataIndex: 'CompanyName',
        },
        {
            title: '创建时间',
            key: 'Time',
            dataIndex: 'Time',
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
            width: 300,
        },
    ]
}
