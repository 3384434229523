var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":_vm.isEdit ? '编辑职务' : '添加职务',"visible":_vm.visible,"confirm-loading":_vm.confirmLoading,"width":"500px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"部门"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
               'Department_id',
                {rules: [{ required: true, message: '请选择部门' }] , initialValue: _vm.isEdit ? _vm.commodityDetail.Department_Name : ''},
            ]),expression:"[\n               'Department_id',\n                {rules: [{ required: true, message: '请选择部门' }] , initialValue: isEdit ? commodityDetail.Department_Name : ''},\n            ]"}],attrs:{"disabled":_vm.isEdit},on:{"change":_vm.handleChange}},_vm._l((_vm.DepartmentData),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.Name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"职务昵称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'Name',
                {rules: [{ required: true, message: '请输入职务昵称' }],initialValue: _vm.isEdit ? _vm.commodityDetail.Name : '' },
            ]),expression:"[\n                'Name',\n                {rules: [{ required: true, message: '请输入职务昵称' }],initialValue: isEdit ? commodityDetail.Name : '' },\n            ]"}],attrs:{"placeholder":"请输入职务昵称"}})],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
               'DescribeText',
            {  rules: [{  message: '请输入描述' }],initialValue: _vm.isEdit ? _vm.commodityDetail.DescribeText : '' },
        ]),expression:"[\n               'DescribeText',\n            {  rules: [{  message: '请输入描述' }],initialValue: isEdit ? commodityDetail.DescribeText : '' },\n        ]"}],attrs:{"placeholder":"请输入描述"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }