
export default {
    columns:[
        {
            title: '公司',
            dataIndex: 'Company_Name',
            key: 'Company_Name',
            width: 200,
        },
        {
            title: '用户昵称',
            dataIndex: 'Name',
            key: 'Name',
            width: 100,
        },
        {
            title: '注册手机',
            dataIndex: 'Phone',
            key: 'Phone',
            width: 150,
        },
        {
            title: '用户类型',
            dataIndex: 'Class',
            key: 'Class',
            width: 150,
        },

        {
            title: '注册时间',
            key: 'NewTime',
            dataIndex: 'NewTime',
            width: 130,
        },
        {
            title: '操作',
            key: 'Operate',
            dataIndex: 'Operate',
            scopedSlots: { customRender: 'Operate' },
            width: 320,
        },
    ]
}
