<template>
  <a-spin :spinning="loading">
    <div class="right">
      <div class="ranking">
        <div class="nav">业务员询价订单排行</div>
        <div class="main">
          <a-table :columns="columns_2" :data-source="data"
                   :pagination="false"
                   :scroll="{ y: 250 }"
                   :rowKey="(record, index) => { return index }"
                   :loading="loading">
            <span slot="id" slot-scope="tags,record,index">
              {{ index + 1 }}
            </span>
          </a-table>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import indexTest from '../moble/indexTest'
import {findBusiness} from "@/api/Statistics/Visualization";

let {columns_2} = indexTest

export default {
  data() {
    return {
      loading: false,
      columns_2: columns_2,
      data: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      findBusiness().then(res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload
          this.data = data
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@mixin ranking {
  position: relative;
  background-color: #fff;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  .nav {
    color: #666666;
    width: 100%;
    height: 55px;
    line-height: 55px;
    font-size: 13px;
    font-weight: bold;
    background-color: #f9f9f9;
    text-indent: 1rem;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    border: 1px solid #e9e9e9;
  }
  .main {
    padding: 20px;
    height: 500px;
    position: relative;

    .table {
      position: absolute;
      right: 10px;
      width: 300px;
    }

    #container {
      position: absolute;
      width: 260px;
      height: 300px;
      left: 10px;
      top: 20px;
    }
  }
}

.right {
  width: 100%;

  .ranking {
    width: 100%;
    height: 820px;
    margin-bottom: 20px;
    @include ranking;
  }
}

.icon {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  display: inline-block;
  margin-right: 5px;
}
</style>