//import request from '../../utils/request.js';

const user = {
    state: {
        info: {},
        token: undefined
    },

    mutations: {
        'SET_TOKEN': (state, token) => {
            console.log(token);
            state.token = token;
        },
        'UPDATE_INFO': (state, info) => {
            state.info = info;
        },
    },

    actions: {
        setToken({commit}, token) {
            commit('SET_TOKEN', token);
        },

        updateUserInfo({commit}, user_info) {
            commit('UPDATE_INFO', user_info);
        }
    }
}

export default user;