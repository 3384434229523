import request from '../utils/request';

//查询
export const FindCheck = query => {
    return request({
        url: process.env.VUE_APP_URL + 'admin/Login/find/check',
        method: 'post',
        data: query
    });
};

