<template>
  <div class="Details">
    <a-button type="link" style="margin-bottom: 10px" @click="black">返回上一级</a-button>
    <a-spin :spinning="loading">
      <div class="Info">
        <div class="id">询价编号：{{data.Order_id}}</div>
        <div class="data">
          <a-row>
            <a-col :span="6" class="text">
              客户来源：主页询价
            </a-col>
            <a-col :span="6" class="text">
              所属业务员： <i v-if="data.Business_id">{{ data.Business_Name }}</i><i v-else
                                                                                    style="color: brown">未分配</i>
            </a-col>
            <a-col :span="6" class="text">
              提交时间: {{ data.NewTime }}
            </a-col>
            <a-col :span="6" class="text">
              询价状态:
              <a-badge v-if="data.InShow=='已处理'" status="success" text="已处理"/>
              <a-badge v-if="data.InShow=='未处理'" status="warning" text="未处理"/>
              <a-badge v-if="data.InShow=='无法处理'" status="error" text="无法处理"/>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="Info" style="padding-top: 70px">
        <a-steps :current="current" style="width: 90%;margin: 0 auto;" progress-dot>
          <a-step>
            <template slot="title">
              询价提交
            </template>
            <span slot="description">{{ data.NewTime }}</span>
          </a-step>
          <a-step>
            <template slot="title">
              业务员送达
            </template>
            <span slot="description">{{ data.Time_Business }}<a-button v-if="!data.Time_Business" size="small"
                                                                       style="margin-top: 10px" @click="openInternal">分配业务员</a-button></span>
          </a-step>
          <a-step>
            <template slot="title">
              标记处理
            </template>
            <span slot="description">{{ data.EndTime }}<a-button v-if="data.Time_Business&&!data.EndTime" size="small"
                                                                 style="margin-top: 10px"
                                                                 @click="opensign">标记处理</a-button></span>
          </a-step>

        </a-steps>
      </div>
      <div class="Info" style="height: 280px;padding-top: 70px">
        <a-descriptions title="客户信息" bordered style="width: 90%;margin: 0 auto" :column="{ xxl: 4}">
          <a-descriptions-item label="姓名">
            {{ data.Name }}
          </a-descriptions-item>
          <a-descriptions-item label="公司名称">
            {{ data.CompanyName }}
          </a-descriptions-item>
          <a-descriptions-item label="提交时间">
            {{ data.NewTime }}
          </a-descriptions-item>
          <a-descriptions-item label="手机号码">
            {{ data.UserPhone }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </a-spin>

    <addBusiness ref="addBusiness"></addBusiness>
    <Endsign ref="Endsign"></Endsign>
  </div>
</template>

<script>
import {FindDetails} from "@/api/Custom/Code";
import addBusiness from './addBusiness.vue'
import Endsign from './Endsign.vue'

export default {
  data() {
    return {
      loading: false,
      id: '',
      data: {},
      current: 0
    }
  },
  mounted() {
    let id = this.$route.query.id
    this.id = id
    this.init()
  },
  methods: {
    opensign() {
      let data = this.data
      this.$refs.Endsign.open(data)
    },
    openInternal() {
      this.$refs.addBusiness.open()
    },
    init() {
      this.loading = true
      let id = this.id
      FindDetails({id}).then(res => {
        this.loading = false
        let payload = res.payload
        let time = payload.NewTime
        payload.NewTime = this.getFormattedCurrentTime(time)
        let Time_Business = payload.Time_Business
        let EndTime = payload.EndTime
        if (Time_Business) {
          payload.Time_Business = this.getFormattedCurrentTime(Time_Business)
          this.current = 1
        }
        if (EndTime) {
          payload.EndTime = this.getFormattedCurrentTime(EndTime)
          this.current = 2
        }
        this.data = payload
      })
    },
    getFormattedCurrentTime(date) {
      const now = new Date(date);
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    black() {
      window.history.go(-1);
    }
  },
  components: {addBusiness, Endsign}
}
</script>

<style scoped lang="scss">
.Details {
  width: 100%;

}

.Info {
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 10px;

  .id {
    position: absolute;
    left: 50px;
    top: 40px;
    color: #666666;
    font-size: 20px;
    font-weight: bold;
  }

  .data {
    position: absolute;
    width: 100%;
    height: 90px;
    line-height: 90px;
    top: 110px;
    font-size: 15px;

    .text {
      text-align: center;
    }
  }
}
</style>