<template>
  <div class="Object">
    <div class="topBlock"></div>
    <a-table :columns="columns" :data-source="data"
             @change="tablePaginationChange"
             :rowKey="(record, index) => { return index }"
             :pagination="pagination"
             class="table"
             :scroll="{ y: 600 }"
             :loading="loading">
     <span slot="Operate" slot-scope="text,record">
       <a-button icon="right-circle" size="small" class="button" type="primary" @click="opanObject(record.id)"
                 ghost>详情</a-button>
     </span>
    </a-table>
  </div>
</template>

<script>
import indexTest from "./moble/indexTest";
import {fetchData} from "@/api/Custom/User";


let {columns} = indexTest

export default {
  data() {
    return {
      columns,
      loading: false,
      data: [],
      pagination: {
        current: 1,//页码坐标
        total: 0, // 总数
        pageSize: 10 // 每页条数，所有页设置统一的条数
      },
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    tablePaginationChange(val) {
      this.pagination.current = val.current
      this.getdata()
    },
    getdata() {
      this.data = []
      this.loading = true
      let pageIndex = this.pagination.current
      let pageSize = this.pagination.pageSize

      fetchData({pageIndex, pageSize}).then(async res => {
        this.loading = false
        if (res.errorCode == 200) {
          let data = res.payload.data
          for (let i in data) {
            let NewTime = new Date(data[i].NewTime);
            data[i].NewTime = NewTime.getFullYear() + '-' + (NewTime.getMonth() + 1) + '-' + NewTime.getDate()
            let LoginTime = data[i].LoginTime
            if (LoginTime) {
              LoginTime = new Date(LoginTime);
              data[i].LoginTime = LoginTime.getFullYear() + '-' + (LoginTime.getMonth() + 1) + '-' + LoginTime.getDate()
            }
          }
          this.data = data
          this.pagination.total = res.payload.total
        } else {
          this.$message.error(res.errorMsg);
        }
      })
    },
    opanObject() {
      this.$router.push('/Custom/User/Details')
    }
  }
}
</script>

<style scoped lang="scss">
.Object {
  width: 100%;
  height: 800px;
  background-color: #ffffff;

  .topBlock {
    width: 100%;
    height: 20px;
  }


  .topNav {
    width: 98%;
    height: 50px;
    margin: 0 auto;

    .search-btn {
      margin-right: 10px;
    }
  }
}

.table {
  width: 98%;
  margin: 0 auto;
}

.green {
  color: green;
}

.red {
  background-color: red;
}

.button {
  display: inline-block;
  margin-right: 15px;
}


</style>
